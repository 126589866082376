import React, {useState, useContext, useEffect} from 'react'
import {Redirect, Prompt} from 'react-router-dom'

import {Formik, FieldArray} from 'formik';
import {Form, Datepicker, SubmitBtn, Input, Textarea} from 'react-formik-ui'
import moment from 'moment';
import * as yup from 'yup'

import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import ReactMarkdown from 'react-markdown'

import AddFileItem from './AddFileItem'

import WeeklogUtils from '../../shared/WeeklogUtils'
import DataContext from '../../DataContext'
import UserContext from '../../UserContext'

import FirebaseConfig from'../../FirebaseConfig'

import '../../css/form.css'

const AddEffort = ({match}) => {

    const {user} = useContext(UserContext);
    const {data, forceUpdateData} = useContext(DataContext);
    const [finalDownload, setFinalDownload] = useState(null);
    const [isShowingPreview, setIsShowingPreview] = useState(false);

    const handleSubmit = (values, {setSubmitting}) => {

        const doSubmitDownload = async(data, autoFinish, callback) => {

            firebase.auth().currentUser.getIdToken(true).then(idToken => {
                const addDownload = async () => {
                    const newRef = await axios.post(`${FirebaseConfig.databaseURL}/downloads.json?auth=${idToken}`, data);
                    await axios.patch(`${FirebaseConfig.databaseURL}/projects/${data.project}/downloads.json?auth=${idToken}`, {[newRef.data.name] : true})
                    setFinalDownload(newRef.data.name);
                    forceUpdateData();
                }
                
                addDownload();
            }).catch(err => {
                console.log("Error submitting", err);
                callback();
            })
        }

        setSubmitting(true);


        const projectIndex = data.projects.findIndex(project => project.name === values.project);
        if(projectIndex === -1) {
            console.log("Invalid project, somehow?");
            setSubmitting(false);
            return;
        }

        const finalTimestamp = moment(values.timestamp).startOf("day")
            .add(moment().hour(), "hours")
            .add(moment().minute(), "minutes")
            .add(moment().second(), "seconds").valueOf();

        const newDownloadData = {
            project: data.projects[projectIndex].id,
            name: values.name,
            timestamp: finalTimestamp,
            content: values.content,
            slug: WeeklogUtils.getUniqueSlug(data, values.name, data.projects[projectIndex]),
            files: values.files.map(file => {
                return {
                    name: file.name,
                    size: file.size,
                    ref: file.ref,
                    url: file.url
                }
            }),
            version: values.version
        }

        doSubmitDownload(newDownloadData, values.autoFinish, () => setSubmitting(false));
    }

    const getSchema = () => {
        return yup.object().shape({
            project: yup.string().required("A project is required"),
            name: yup.string().required("A name is required"),
            content: yup.string().notRequired(),
            timestamp: yup.date().required("A date is required"),
            version: yup.string().notRequired(),
            files: yup.array().required("At least one file is required").min(1, "At least one file is required")
        });
    }

    const [initialValues, setInitialValues] = useState({
        project: false,
        name: "",
        timestamp: moment().toDate(),
        content: "",
        version: "",
        files: []
    });

    useEffect(() => {
        if(!match.params.pid) return;

        const targetProject = data.projects.find(project => project.id === match.params.pid);
        if(!targetProject) return;

        const lastVersion = WeeklogUtils.getProjectVersion(WeeklogUtils.getPopulatedProject(targetProject, data));

        setInitialValues({
            project: targetProject.name,
            name: "",
            timestamp: moment().toDate(),
            content: "",
            version: lastVersion,
            files: []
        })

    }, [match.params, data])


    

    if(!user) return <Redirect to={`${process.env.PUBLIC_URL}/`} />
    if(finalDownload !== null) {
        return <Redirect to={`${process.env.PUBLIC_URL}/dashboard/project/${match.params.pid}/downloads`} />
    }

    return (
        <div className="container">
            <h1>Add Download</h1>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={getSchema}
                onSubmit={handleSubmit}
            >{({values, dirty, isSubmitting, setFieldValue}) => (
                <Form>
                    <Prompt when={dirty} message="You have unsaved changes. If you leave now, those changes will be lost." />
                    <Input name="project" disabled={true} label="Project" autoComplete="off" />
                    <Input name="name" label="Name" autoComplete="off" />
                    <Datepicker name="timestamp" label="Date" />
                    <Input name="version" label="Version" autoComplete="off" />
                    <div className="content-previewable-wrapper">
                        <div className="content-previewable-label">
                            <span>Content</span>
                            <button type="button" onClick={() => setIsShowingPreview(!isShowingPreview)}>{isShowingPreview ? "Hide Preview" : "Show Preview"}</button>
                        </div>
                        {isShowingPreview ? <div className="content-preview"><ReactMarkdown source={values.content} /></div> : <Textarea name="content" label="" autoComplete="off" /> }
                    </div>
                    <FieldArray name="files" render={arrayHelpers => { return (
                        <div className="form-element images">
                            <div className="images-wrapper">
                                <span className="label">Files</span>
                                <div className="images-container">
                                {values.files && values.files.length > 0 ? (
                                    values.files.map((file, index) => { return (
                                        <AddFileItem key={"file-" + index} fileArrayName="files" index={index} arrayHelpers={arrayHelpers} values={values} setFieldValue={setFieldValue} />
                                    )})
                                ) : (
                                    null
                                )}
                                <div className="add-image-container">
                                    <button type="button" onClick={() => arrayHelpers.push({url: "", caption: ""})}>Add a File</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    )}} />
                    <div className="form-element">
                        <div className="buttons-wrapper">   
                            <SubmitBtn disabled={isSubmitting} children={isSubmitting ? "Submitting..." : "Submit"} />
                        </div>
                    </div>
                </Form>
            )}</Formik>
        </div>
    )
}

export default AddEffort;