import React, {useState, useMemo, useEffect} from 'react'
import {useHistory} from 'react-router-dom'

import moment from 'moment'
import { useTable, useSortBy } from 'react-table'

import WeeklogUtils from '../../shared/WeeklogUtils'

const DashboardProjectEntries = ({project}) => {
    const [data, setData] = useState([]);
    const columns = useMemo(() => [
        {
            Header: 'Summary',
            accessor: 'summary',
        },
        {
            Header: 'Date',
            accessor: 'timestamp',
            Cell: ({value}) => {
                return moment(value, "x").format(window.innerWidth > 640 ? "Do MMMM YYYY" : "DD/MM/YY")
            }
        },
        {
            Header: 'id',
            accessor: 'id'
        }
    ], []);
    
    useEffect(() => {
        setData(project.entries.map(entry => {
            return {
                summary: entry.summary,
                timestamp: entry.timestamp,
                id: entry.id,
            }
        }))
    }, [project]);
    const history = useHistory();

    const navigateToEntry = id => {
        history.push(`${process.env.PUBLIC_URL}/entries/${project.entries.find(e => e.id === id).slug}`);
    }

    if(!data || data.length === 0) {
        return <p>No entries found</p>
    }
    

    return <Table columns={columns} data={data} onRowClick={navigateToEntry} project={project} />;
}

const Table = ({columns, data, onRowClick, project}) => {
    const tableInstance = useTable({
        columns, 
        data, 
        initialState: {
            hiddenColumns: ["id"],
            sortBy: [
                {
                    id: 'timestamp',
                    desc: true
                }
            ]
        }
    }, useSortBy);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    if(!data || data.length === 0) return null;

    return (
        <table {...getTableProps()} className="dashboard-project-table">
            <thead>
            {// Loop over the header rows
            headerGroups.map(headerGroup => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                headerGroup.headers.map(column => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        <span>
                            {column.isSorted 
                                ? column.isSortedDesc ? "▼" : "▲"
                                : "▶"
                            }
                        </span>
                    </th>
                ))}
                </tr>
            ))}
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
            {// Loop over the table rows
            rows.map(row => {
                // Prepare the row for display
                prepareRow(row)
                const entryEffort = WeeklogUtils.getEntryEffort(row.values, project.efforts);
                return (
                    <tr {...row.getRowProps()} onClick={() => onRowClick(row.values.id)} style={{
                        color: WeeklogUtils.getStatusColor(entryEffort ? entryEffort.type : null)
                    }}>
                        {// Loop over the rows cells
                        row.cells.map(cell => {
                        // Apply the cell props
                        return (
                            <td {...cell.getCellProps()}>
                            {// Render the cell contents
                            cell.render('Cell')}
                            </td>
                        )
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

export default DashboardProjectEntries;