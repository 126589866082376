import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

import moment from 'moment'

import WeeklogUtils from '../../shared/WeeklogUtils'

const SelectedProject = ({project}) => {

    const [activeYears, setActiveYears] = useState(null);
    useEffect(() => {
        let years = [moment(project.timestamp, "x").year()];

        if(project.entries) {
            project.entries.forEach(entry => {
                const entryYear = moment(entry.timestamp, "x").year();
                if(years.findIndex(y => y === entryYear) === -1) years.push(entryYear);
            });
        }
        if(project.entries) {
            project.efforts.forEach(effort => {
                const startYear = moment(effort.startDate, "x").year();
                if(years.findIndex(y => y === startYear) === -1) years.push(startYear);

                if(effort.endDate) {
                    const endYear = moment(effort.endDate, "x").year();
                    if(years.findIndex(y => y === endYear) === -1) years.push(endYear);
                }
            });
        }
        if(years.length > 1) {
            years.sort();
            years = [years[0], years[years.length - 1]];
        }

        setActiveYears(years);
    }, [project])

    if(!activeYears) return null;

    return (
        <Link to={`${process.env.PUBLIC_URL}/projects/${project.slug}`} className="selected-project" style={{
            backgroundImage: `url(${WeeklogUtils.getProjectImage(project).url})`
        }}>
            <div className="selected-project-content">
                <h2>{project.name}, {activeYears.join(" - ")}</h2>
                <h4>{project.description}</h4>
            </div>
        </Link>
    )
};

export default SelectedProject;