import React from 'react'

import moment from 'moment'

const EffortMonthMarker = ({timestamp, top}) => {
    return (
        <div style={{
            position: "absolute",
            width: "100%",
            zIndex: -1,
            height: "1px",
            backgroundColor: "rgba(255,255,255,0.2)",
            left: "0px",
            top: top + "px",
        }}>
            <h3 style={{
                margin: "0", 
                position: "absolute", 
                left: "0px",
            }}>{moment(timestamp, "x").subtract(1, "months").format("MMMM YYYY")}</h3>
        </div>
    )
}

export default EffortMonthMarker;