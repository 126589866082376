import React, {useState, useEffect, useContext} from 'react'
import {Link} from 'react-router-dom'

import moment from 'moment'
import ReactMarkdown from 'react-markdown'

import DataContext from '../../DataContext'

import ProjectHeader from '../projects/ProjectHeader'

const DownloadDetail = ({match}) => {
    const [download, setDownload] = useState(null);
    const {data} = useContext(DataContext);

    useEffect(() => {
        let rawDownload = data.downloads.find(d => d.slug === match.params.dslug);
        setDownload({
            ...rawDownload,
            project: data.projects[rawDownload.project.index]
        });
    }, [match.params, data.downloads, data.projects])
    
    if(!download) return null;

    return (
        <ProjectHeader project={download.project} page={"downloads"}>
            <div className="download">
                <h3>
                    <Link to={`${process.env.PUBLIC_URL}/downloads/${download.slug}`}>Version {download.version}</Link>
                    <Link className="edit-button" to={`${process.env.PUBLIC_URL}/downloads/edit/${download.id}`}>✎</Link>
                </h3>
                <p>{moment(download.timestamp, "x").format("Do MMMM YYYY")}</p>
                <div>
                    <h4>{download.name}</h4>
                    <ReactMarkdown source={download.content} />
                </div>
                <div className="files">
                    <h3>Files</h3>
                    <ul>
                        {download.files.map((file, index) => {
                            return <li key={index}><a download={file.url} href={file.url} >
                                <p>{file.name}</p>
                                <p>{`${(file.size / (file.size > 1000000 ? 1000000 : 1000)).toFixed(1)} ${(file.size > 1000000 ? "MB" : "kB")}`}</p>
                            </a></li>
                        })}
                    </ul>
                </div>
            </div>
        </ProjectHeader>
    )
}

export default DownloadDetail;