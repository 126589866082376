import { reindexProject, reindexResource, reindexIdeas } from './reindexing'

const obscureSecrets = (projects, ideas, entries, efforts, downloads) => {
    //Obscure secret and top-secret projects
    projects = projects
        .filter(project => project.visibility !== undefined)
        .map(project => {
            switch(project.visibility) {
            case "hidden":
                //Because this involves the removal of data, we deal with hidden
                //projects below in a separate step
                break;
            case "secret":
                //For merely 'secret' projects, just hide any entry content
                project.entries.forEach(entry => {
                    entries[entry.index].content = "This project is still a secret!";
                    entries[entry.index].images = false;
                })
                //Also hide featured images
                project.featuredImages = false;
                break;
            case "top-secret":
                //For 'top secret' projects, hide entry content, but also hide entry summaries and even the project name and description!
                project.name = "Secret Project";
                project.description = "I can't talk about this project yet!";
                project.content = "This project has been marked as top-secret, so this content is hidden."
                project.featuredImages = false;

                project.entries.forEach(entry => {
                    entries[entry.index].content = "This project is still a secret!";
                    entries[entry.index].summary = "Secret project work...";
                    entries[entry.index].images = false;
                })
                //Also obscure effort summaries
                project.efforts.forEach(effort => {
                    efforts[effort.index].summary = "Secret project effort...";
                })
                //And download names
                project.downloads.forEach(download => downloads[download.index].name = "Secret download...");
                break;
            case "open":
                //all good
                break;
            default:
                console.log(`Unexpected value ${project.visibility} for project`, project);
            }

            return project;
        });

    //Remove entries, efforts and downloads associated with hidden projects
    projects
        .filter(project => project.visibility === "hidden")
        .forEach(project => {
            // Remove entries
            const entryIds = project.entries.map(entry => entry.id);
            entries = entries.filter((entry) => !entryIds.includes(entry.id));

            // Remove efforts
            const effortIds = project.efforts.map(effort => effort.id);
            efforts = efforts.filter((effort) => !effortIds.includes(effort.id));

            // Remove downloads
            const downloadIds = project.downloads.map(download => download.id);
            downloads = downloads.filter((download) => !downloadIds.includes(download.id));
        });

    //Remove hidden projects
    projects = projects.filter(project => project.visibility !== "hidden");

    // Reindex now that we've altered projects, ideas, entries, efforts and downloads
    projects = projects.map(project => reindexProject(project, ideas, entries, efforts, downloads));
    ideas = reindexIdeas(ideas, projects);
    entries = reindexResource(entries, projects);
    efforts = reindexResource(efforts, projects);
    downloads = reindexResource(downloads, projects);

    return { projects, ideas, entries, efforts, downloads };
};

export default obscureSecrets;
