import React from 'react'

import ReactMarkdown from 'react-markdown'
import ImageGallery from '../common/ImageGallery'

const ProjectDetailOverview = ({project}) => {
    return (
        <div className="project-detail-body">
            <h2>Overview</h2>
            <ReactMarkdown source={project.content} />
            {project.featuredImages && project.featuredImages.length > 0 ? <ImageGallery images={project.featuredImages} width={window.innerWidth > 640 ? 1000 : window.innerWidth - 60} /> : null }
        </div>
    )
}

export default ProjectDetailOverview;