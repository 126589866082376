import React, { useContext, useState, useEffect } from 'react'

import moment from 'moment'
import ReactMarkdown from 'react-markdown'

import DataContext from "../../DataContext"

import ProjectGroup from './ProjectGroup'
import ProjectFilters from './ProjectFilters'

import WeeklogUtils from '../../shared/WeeklogUtils'

import "./css/projects.css"

const filterProject = (project, filters, mode) => {
    if(!filters || filters.length === 0) return true;

    if(mode === "OR") {
        for(let i = 0; i < project.tags.length; i++) {
            if(filters.findIndex(t => t === project.tags[i]) !== -1) return true;
        }
    } else {
        return filters.reduce((acc, tag) => {
            return acc && project.tags.findIndex(t => t === tag) !== -1
        }, true);
    }

    return false;
}

const Projects = () => {
    const {data} = useContext(DataContext);

    const [filteredProjects, setFilteredProjects] = useState([]);
    const [projectGroups, setProjectGroups] = useState([]);
    const [filters, setFilters] = useState([[], [], ["medium", "large"]]);
    const [filterMode, setFilterMode] = useState(["OR", "ALL", "OR"]);
    const [numFilters, setNumFilters] = useState(0);

    useEffect(() => {
        setNumFilters(!filters ? 0 : filters.reduce((acc, filter) => acc + filter.length, 0))
    }, [filters])

    useEffect(() => {
        setFilteredProjects(data.projects.filter(project => {
            if(!filters || numFilters === 0) return true;
            if(!project.tags) return false;
            let filterPass = true;
            for(let i = 0; i < filters.length; i++) {
                filterPass = filterPass && filterProject(project, filters[i], filterMode[i]);
            }
            return filterPass;
        }));
    }, [data.projects, filters, filterMode, numFilters])

    const handleToggleFilter = (filter, group) => {
        if(filters[group].findIndex(f => f === filter) !== -1) {
            setFilters(
                filters.map((filterGroup, index) => index !== group
                    ? filterGroup
                    : filterGroup.filter(f => f !== filter)
                )
            );
        } else {
            setFilters(
                filters.map((filterGroup, index) => index !== group
                    ? filterGroup
                    : [...filterGroup, filter])
            );
        }
    }

    const handleToggleFilterMode = group => {
        setFilterMode(filterMode.map((f, i) => i === group ? (f === "OR" ? "AND" : "OR") : f));
    }

    useEffect(() => {
        const projects = filteredProjects.map(project => WeeklogUtils.getPopulatedProject(project, data));
        const sevenDay = moment().subtract(7, "day");
        const thirtyDay = moment().subtract(30, "day");
        const sevenDayProjects = projects.filter(project => {
            return !WeeklogUtils.isProjectEmpty(project) && moment(WeeklogUtils.getProjectLastUpdatedTimestamp(project), "x").isAfter(sevenDay)
        });
        const thirtyDayProjects = projects.filter(project => {
            return !WeeklogUtils.isProjectEmpty(project) && moment(WeeklogUtils.getProjectLastUpdatedTimestamp(project), "x").isBetween(thirtyDay, sevenDay)
        });
        const olderProjects = projects.filter(project => {
            return !WeeklogUtils.isProjectEmpty(project) && moment(WeeklogUtils.getProjectLastUpdatedTimestamp(project), "x").isBefore(thirtyDay)
        })
        let yearGroups = {};
        for(let i = 0; i < olderProjects.length; i++) {
            const year = moment(WeeklogUtils.getProjectLastUpdatedTimestamp(olderProjects[i]), "x").format("YYYY");
            if(yearGroups[year]) yearGroups[year].push(olderProjects[i]);
            else yearGroups[year] = [olderProjects[i]];
        }
        yearGroups = Object.keys(yearGroups).sort((a, b) => b - a).map(year => <ProjectGroup key={"project-"+year} projects={yearGroups[year]} title={Number(year) === moment().year() ? "Earlier this year" : year} />);
        setProjectGroups([
            <ProjectGroup key={'sevenDay'} projects={sevenDayProjects} title={"Updated in the last week"} />,
            <ProjectGroup key={'thirtyDay'} projects={thirtyDayProjects} title={"Updated in the last month"} />,
            ...yearGroups
        ]);
    }, [filteredProjects, data]);

    return (
        <div className="container">
            <div className="projects-intro">
                <h1>Projects</h1>
                <ReactMarkdown source={data.config.projectsIntro} />
            </div>
            <ProjectFilters activeFilters={filters} numFilters={numFilters} filterModes={filterMode} toggleFilter={handleToggleFilter} setMode={handleToggleFilterMode}/>
            {filteredProjects && filteredProjects.length > 0
                ? projectGroups
                : <h3 style={{margin: "3em", textAlign: "center"}}>No projects match your selected filters</h3>}
        </div>
    )
}

export default Projects;
