import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'

import moment from 'moment'

import WeeklogUtils from '../../shared/WeeklogUtils'

const CurrentEffort = ({effort}) => {

    const [lastEntry, setLastEntry] = useState(null);
    useEffect(() => {
        setLastEntry(WeeklogUtils.getEffortEntries(effort, effort.entries)[0]);
    }, [effort]);

    return (
        <Link to={`${process.env.PUBLIC_URL}/projects/${effort.project.slug}/entries`} className="current-effort">
            <h3>{effort.summary}</h3>
            <p className="effort-name">{effort.project.name}</p>
            <p className="effort-type" style={{
                fontWeight: 700, 
                color: WeeklogUtils.getStatusColor(effort.type)
            }}>
                Effort Type: {WeeklogUtils.capitalize(effort.type)}
            </p>
            {effort.endDate
                ? <p>Finished {moment(effort.endDate, "x").fromNow()}</p>
                : <p>Started {moment(effort.startDate, "x").fromNow()}</p>
            }
            { lastEntry
                ? <p>Last Entry posted {moment(lastEntry.timestamp, "x").fromNow()}</p>
                : <p>No Entries yet</p>
            }
        </Link>
    )
}

export default CurrentEffort;