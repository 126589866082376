import React, {useState, useContext, useEffect} from 'react'
import {Redirect, Prompt} from 'react-router-dom'

import {Formik} from 'formik';
import {Form, Autocomplete, Datepicker, SubmitBtn, Input, Textarea} from 'react-formik-ui'
import moment from 'moment';
import * as yup from 'yup'

import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import ReactMarkdown from 'react-markdown'

import WeeklogUtils from '../../shared/WeeklogUtils'
import DataContext from '../../DataContext'
import UserContext from '../../UserContext'

import FirebaseConfig from'../../FirebaseConfig'

import '../../css/form.css'

const EditEffort = ({match}) => {

    const {user} = useContext(UserContext);
    const {data, forceUpdateData} = useContext(DataContext);
    const [suggestions, setSuggestions] = useState({});
    const [editComplete, setEditComplete] = useState(false);
    const [effort, setEffort] = useState(null);
    const [initialValues, setInitialValues] = useState({
        project: "",
        type: "",
        summary: "",
        content: "",
        startDate: moment().startOf("day").add(12, "hour").toDate(),
    });
    const [isShowingPreview, setIsShowingPreview] = useState(false);

    useEffect(() => {
        setSuggestions(data.projects.map(project => project.name));
    }, [data.projects]);

    useEffect(() => {
        const rawEffort = data.efforts.find(e => e.id === match.params.eid);
        if(rawEffort === undefined) {
            setEffort(null);
        } else {
            setEffort({...rawEffort, project: data.projects[rawEffort.project.index]});
        }
    }, [data.efforts, data.projects, match.params.eid]);

    useEffect(() => {
        if(effort) {
            setInitialValues({
                project: effort.project.name,
                type: effort.type,
                summary: effort.summary,
                content: effort.content,
                startDate: moment(effort.startDate, "x").toDate()
            });
        }
    }, [effort])

    const handleSubmit = (values, {setSubmitting}) => {

        const doPatchEffort = async(data, id, callback) => {
            firebase.auth().currentUser.getIdToken(true).then(idToken => {
                const patchEffort = async () => {
                    await axios.patch(`${FirebaseConfig.databaseURL}/efforts/${id}.json?auth=${idToken}`, data)
                    
                    setEditComplete(true);
                    forceUpdateData();
                }
                
                patchEffort();
            }).catch(err => {
                console.log("Error submitting", err);
                callback();
            })
        }

        setSubmitting(true);

        const projectIndex = data.projects.findIndex(project => project.name === values.project);
        const newEffortData = {
            summary: values.summary,
            slug: WeeklogUtils.getUniqueSlug(data, values.summary, data.projects[projectIndex], effort.id),
            content: values.content,
        }

        doPatchEffort(newEffortData, match.params.eid, () => setSubmitting(false));
    }

    const getSchema = () => {
        return yup.object().shape({
            summary: yup.string().required("A summary is required"),
            content: yup.string().notRequired(),
        });
    }

    if(!user) return <Redirect to={`${process.env.PUBLIC_URL}/`} />
    if(effort === null) return <div><h1>Effort not found!</h1></div> //TODO make a nice 404 page or something 
    if(editComplete) return <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />

    return (
        <div className="container">
            <h1>Edit Effort</h1>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={getSchema}
                onSubmit={handleSubmit}
            >{({values, dirty, isSubmitting}) => (
                <Form>
                    <Prompt when={dirty} message="You have unsaved changes. If you leave now, those changes will be lost." />
                    <Autocomplete name="project" label="Project" suggestions={suggestions} autoComplete="off" disabled={true} />
                    <Input name="type" label="Type" disabled={true} />
                    <Input name="summary" label="Summary" autoComplete="off" />
                    <Datepicker name="startDate" label="Start Date" disabled={true} />
                    <div className="content-previewable-wrapper">
                        <div className="content-previewable-label">
                            <span>Content</span>
                            <button type="button" onClick={() => setIsShowingPreview(!isShowingPreview)}>{isShowingPreview ? "Hide Preview" : "Show Preview"}</button>
                        </div>
                        {isShowingPreview ? <div className="content-preview"><ReactMarkdown source={values.content} /></div> : <Textarea name="content" label="" autoComplete="off" /> }
                    </div>
                    <div className="form-element">
                        <div className="buttons-wrapper">   
                            <SubmitBtn disabled={isSubmitting} children={isSubmitting ? "Submitting..." : "Submit"} />
                        </div>
                    </div>
                </Form>
            )}</Formik>
        </div>
    )
}

export default EditEffort;