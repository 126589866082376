import React from 'react'

import WeeklogUtils from '../../shared/WeeklogUtils'

const Footer = () => {
    return (
        <div className="footer">
            WeekLog Engine v{WeeklogUtils.version} - Made with love by Lachlan in 2020
        </div>
    );
}

export default Footer;