import React, {useEffect, useContext} from 'react'
import {Redirect} from 'react-router-dom'

import firebase from 'firebase/app'
import 'firebase/auth'

import UserContext from '../../UserContext'

const Logout = () => {
    const {user} = useContext(UserContext);

    useEffect(() => {
        if(!user) return;

        firebase.auth().signOut();

    }, [user])  

    if(!user) return <Redirect to={`${process.env.PUBLIC_URL}/`} />

    return (
        <div className="container"></div>
    )
}

export default Logout;