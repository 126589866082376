import React from 'react'
import {Link} from 'react-router-dom'

import ReactMarkdown from 'react-markdown'
import moment from 'moment'

import './css/entry-tile.css'

const EntryTile = ({entry}) => {

    const backgroundStyle = entry.images && entry.images.length > 0
        ? {backgroundImage:`url(${entry.images[0].url})`} : null;
    return (
        <div className="entry-tile" style={backgroundStyle}>
            <Link className="hover-tile" to={`${process.env.PUBLIC_URL}/entries/${entry.slug}`}>
                <h4>{!entry.label 
                    ? null
                    : entry.label === "note" 
                        ? "📝 " 
                        : entry.label === "elaboration" 
                            ? "🎨 " 
                            : "🧪 "
                }{entry.summary}</h4>
                <p className="entry-tile-date">{moment(entry.timestamp, "x").fromNow()}</p>
                <div className="entry-tile-content">
                    <ReactMarkdown source={entry.content.substring(0, 200) + "..."} disallowedTypes={["link"]} unwrapDisallowed={true} />
                </div>
            </Link>
        </div>

    )
}

export default EntryTile;