import React, {useContext} from 'react'
import {Link} from 'react-router-dom'

import Search from '../search/Search'

import './css/navbar.css'

import UserContext from '../../UserContext'
import DataContext from '../../DataContext'

const Navbar = ({showButtons}) => {

    const {user} = useContext(UserContext);
    const {data} = useContext(DataContext);

    return (
        <nav className="main-nav">
            <div className="container">
                <div className="logo">
                    <div>
                        <Link to={`${process.env.PUBLIC_URL}/`}>{data.config.navTitle}</Link>
                    </div>
                </div>
                { !showButtons ? <div></div> : (
                <div>
                    <Search />
                    <ul>
                        <li><Link to={`${process.env.PUBLIC_URL}/timeline`}>Timeline</Link></li>
                        {data.config.showEffortsTimeline ? <li><Link to={`${process.env.PUBLIC_URL}/efforts`}>Efforts</Link></li> : null}
                        <li><Link to={`${process.env.PUBLIC_URL}/projects`}>Projects</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/entries`}>Entries</Link></li>
                    </ul>
                    { user
                        ? (<ul>
                            <li><Link to={`${process.env.PUBLIC_URL}/entries/add`}>Add Entry</Link></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/dashboard`}>Dashboard</Link></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/logout`}>Logout</Link></li>
                        </ul>)
                        : (<ul>
                            <li><Link to={`${process.env.PUBLIC_URL}/login`}>Login</Link></li>
                        </ul>)
                    }
                </div>
                )}
            </div>
        </nav>
    )
}

export default Navbar;
