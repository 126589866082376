import React from 'react';
import {Link} from 'react-router-dom'

import ReactMarkdown from 'react-markdown'
import moment from 'moment'

const RecentEntry = ({entry}) => {
    return (
        <div className="recent-entry" style={{
            backgroundImage: `url(${entry.images && entry.images.length > 0 ? entry.images[0].url : null})`
        }}>
            <Link to={`${process.env.PUBLIC_URL}/entries/${entry.slug}`} className="recent-entry-inner">
                <h3>{entry.summary}</h3>
                <p>{entry.project.name}</p>
                <div className="recent-entry-preview">
                    <ReactMarkdown source={entry.content.substring(0, 200) + "..."} disallowedTypes={["link"]} unwrapDisallowed={true} />
                </div>
                <p>Posted {moment(entry.timestamp, "x").fromNow()}</p>
            </Link>
        </div>
    )
}

export default RecentEntry;