import React from 'react'
import {Link, useHistory} from 'react-router-dom'

const SearchResult = ({match, handleClick}) => {

    const history = useHistory();

    const handleClickSequence = target => {
        history.push(target);
        handleClick();
    }

    return (
        <Link className="search-result" to={match.item.link} onMouseDown={() => handleClickSequence(match.item.link)}>
            <h2>{match.item.name}</h2>
            <p><span>{match.item.type === "Project" ? "Project" : `Entry in Project '${match.item.project.name}'`}</span> | <span>Score: {Math.round(100 * (1.0 - match.score))}%</span></p>
        </Link>
    )
}

export default SearchResult;