import React, {useContext} from 'react'
import {Link} from 'react-router-dom'

import UserContext from '../../UserContext'
import WeeklogUtils from '../../shared/WeeklogUtils'
import ProjectDetailNav from './ProjectDetailNav'

const ProjectHeader = ({project, children, page}) => {

    const {user} = useContext(UserContext);

    const editButton = user && user.access === "admin" ? (
        <Link className="edit-button" to={`${process.env.PUBLIC_URL}/dashboard/project/${project.id}/edit`}>✎</Link>
    ) : null;

    return (
        <div>
            <div className="project-detail-header-wrapper" style={{backgroundImage: `url(${WeeklogUtils.getProjectImage(project).url})`}}>
                <div className="project-detail-header">
                    <div>
                        <h1>{project.name} {editButton}</h1>
                        <p>{project.description}</p>
                    </div>
                </div>
            </div>
            <div className="container">
                <ProjectDetailNav project={project} page={page} />
                {children}
            </div>
        </div>
    )
}

export default ProjectHeader;