import React, {useEffect, useState, useContext} from 'react'
import {Link} from 'react-router-dom'

import moment from 'moment'
import ReactMarkdown from 'react-markdown'

import DataContext from '../../DataContext'
import UserContext from '../../UserContext'

import ProjectHeader from '../projects/ProjectHeader'
import ImageGallery from '../common/ImageGallery'
import EntryTile from './EntryTile'
import EntryEffortIndicator from './EntryEffortIndicator'

import './css/entry-detail.css'
import WeeklogUtils from '../../shared/WeeklogUtils'

const EntryDetail = ({match}) => {

    const [project, setProject] = useState(null);
    const [effort, setEffort] = useState(null);
    const [entry, setEntry] = useState(null);
    const [nextEntry, setNextEntry] = useState(null);
    const [prevEntry, setPrevEntry] = useState(null);
    const {data} = useContext(DataContext);
    const {user} = useContext(UserContext);

    useEffect(() => {
        const myIndex = data.entries.findIndex(entry => entry.slug === match.params.eslug);

        //Get project and entry
        const myProject = data.projects[data.entries[myIndex].project.index];
        const myProjectIndex = myProject.entries.findIndex(searchEntry => searchEntry.id === data.entries[myIndex].id);
        setEntry(data.entries[myProject.entries[myProjectIndex].index]);
        setProject({...myProject, entries:myProject.entries.map(entryAddr => data.entries[entryAddr.index])});

        //Get effort, if applicable
        const myTs = moment(data.entries[myIndex].timestamp, "x");
        const myDay = myTs.year() * 365 + myTs.dayOfYear();
        const relevantEfforts = data.efforts.filter(e => {
            const start = moment(e.startDate, "x");
            const end = e.endDate ? moment(e.endDate, "x") : moment().add(100, "years");
            const startDay = start.year() * 365 + start.dayOfYear();
            const endDay = end.year() * 365 + end.dayOfYear();

            return e.project.id === data.entries[myIndex].project.id && (startDay === myDay || endDay === myDay);
        });
        if(relevantEfforts.length === 0) {
            setEffort(null);
        } else if(relevantEfforts.length === 1) {
            //an effort started or ended with this entry
            //If that effort has the same start and end dates, then this entry WAS an effort
            setEffort(relevantEfforts[0]);
        } else {
            setEffort(null);
            console.log("Error! Two efforts shared a date, this shouldn't be allowed!", relevantEfforts);
        }

        //Get next and previous entries
        const nextAttempt = myProject.entries[myProjectIndex - 1];
        setNextEntry(nextAttempt ? data.entries[nextAttempt.index] : null);
        const prevAttempt = myProject.entries[myProjectIndex + 1];
        setPrevEntry(prevAttempt ? data.entries[prevAttempt.index] : null);
    }, [match.params.eslug, data.entries, data.projects, data.efforts]);

    if(!entry) return <div></div>

    const editButton = user && user.access === "admin" ? (
        <Link className="edit-button" to={`${process.env.PUBLIC_URL}/entries/edit/${entry.id}`}>✎</Link>
    ) : null;

    return (
        <ProjectHeader project={project} page={"entries"}>
            <div className="entry-detail">
                <h1>{!entry.label 
                    ? null
                    : entry.label === "note" 
                        ? "📝 " 
                        : entry.label === "elaboration" 
                            ? "🎨 " 
                            : "🧪 "
                }{entry.summary} {editButton}</h1>
                <p>{moment(entry.timestamp, "x").format("Do MMMM YYYY")}</p>
                <ReactMarkdown source={entry.content} />
                {effort === null ? null : <EntryEffortIndicator effort={effort} entryMoment={moment(entry.timestamp, "x")} />
                }
                {entry.images ? <ImageGallery images={entry.images} width={window.innerWidth > 640 ? 1000 : window.innerWidth - 60} /> : null}
                <div className="next-prev-container">
                { prevEntry ? (
                    <div>
                        <h3 style={window.innerWidth > 640 ? {textAlign:"center"} : null}>
                            {(`${WeeklogUtils.capitalize(moment(prevEntry.timestamp, "x").from(moment(entry.timestamp, "x"), true))} earlier`).replace("A day earlier", "The previous day")}
                        </h3>
                        <EntryTile entry={prevEntry} />
                    </div>
                ) : (
                    <p className="no-more">This is the oldest entry for this Project</p>
                )}
                { nextEntry ? (
                    <div>
                        <h3 style={window.innerWidth > 640 ? {textAlign:"center"} : null}>
                            {(`${WeeklogUtils.capitalize(moment(entry.timestamp, "x").to(moment(nextEntry.timestamp, "x"), true))} later`).replace("A day later", "The next day")}
                        </h3>
                        <EntryTile entry={nextEntry} />
                    </div>
                ) : (
                    <p className="no-more">This is the most recent entry for this Project</p>
                )}
                </div>
            </div>
        </ProjectHeader>
    )
}

export default EntryDetail;