import React, {useEffect, useState} from 'react'

import ProjectTile from './ProjectTile'

const ProjectGroup = ({title, projects}) => {

    const [projectTiles, setProjectTiles] = useState([]);

    useEffect(() => {
        setProjectTiles(projects && projects.length > 0 
            ? projects.map(p => <ProjectTile key={p.id} project={p} />)
            : []);
    }, [projects])

    if(!projects || projects.length === 0) return null;

    return (
        <div className="project-group">
            <h2>{title}</h2>
            <div className="project-tile-container">{projectTiles}</div>
        </div>
    )
}

export default ProjectGroup;