import React, {useState, useContext, useEffect} from 'react'
import {Redirect} from 'react-router-dom'

import UserContext from '../../UserContext'
import DataContext from '../../DataContext'

import DashboardProjectNav from './DashboardProjectNav'
import DashboardProjectEfforts from './DashboardProjectEfforts'
import DashboardProjectDownloads from './DashboardProjectDownloads'
import EditProject from '../projects/EditProject'
import DashboardProjectEntries from './DashboardProjectEntries'

import './css/dashboard.css'

import WeeklogUtils from '../../shared/WeeklogUtils'

const DashboardProject = ({match}) => {
    const {user} = useContext(UserContext);
    const {data} = useContext(DataContext);

    const [project, setProject] = useState(null);
    const [page, setPage] = useState(null);

    useEffect(() => {
        const rawProject = data.projects.find(p => p.id === match.params.pid);
        if(!rawProject) {
            console.log("Project not found");
            setProject(null);
            return;
        }

        setProject(WeeklogUtils.getPopulatedProject(rawProject, data));
    }, [match.params.pid, data])

    useEffect(() => {
        if(!project) {
            setPage(null);
            return;
        }

        switch(match.params.page) {
            case "efforts":
                setPage(<DashboardProjectEfforts project={project} />);
                break;
            case "edit":
                setPage(<EditProject project={project} match={match} />);
                break;
            case "downloads":
                setPage(<DashboardProjectDownloads project={project} />);
                break;
            default:
                setPage(<DashboardProjectEntries project={project} />);
                break;
        }
    }, [match, project])
    
    if(!user) return <Redirect to={`${process.env.PUBLIC_URL}/`} />
    
    if(!project) return null;

    return (
        <div className="container dashboard">
            <header>
                <div>
                    <h1>{project.name}</h1>
                    <h4>{project.description}</h4>
                </div>
            </header>
            <DashboardProjectNav project={project} page={match.params.page}/>
            {page}
        </div>
    )
}

export default DashboardProject;