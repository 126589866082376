import React from 'react'
import ReactMarkdown from 'react-markdown'

const EntryLabeller = ({entry, onClicked, loading}) => {
    return (
        <div className="entryToLabel">
            <h3>{entry.project.name} - {entry.summary}</h3>
            <div>
                <ReactMarkdown source={entry.content} />
            </div>
            {loading ? <ul><li><span role="img" aria-label="spinner">🔄</span>Updating...</li></ul> : (<ul>
                <li onClick={() => onClicked(entry, "note")}><span role="img" aria-label="note">📝</span>Note</li>
                <li onClick={() => onClicked(entry, "experimentation")}><span role="img" aria-label="experiment">🧪</span>Experimentation</li>
                <li onClick={() => onClicked(entry, "elaboration")}><span role="img" aria-label="elaboration">🎨</span>Elaboration</li>
            </ul>)}
        </div>
    )
}

export default EntryLabeller;