import moment from 'moment'

export default class TimelineCalcs {

    static firstMonthOffset = 144;
    static offset = 164;
    static height = 5000;

    // Converts a provided timestamp into a y pixel coordinate
    static timestampToY(timestamp, timeBounds) {
        return this.offset + this.height * ((timeBounds.max - timestamp) / (timeBounds.max - timeBounds.min));
    }

    // Calculate time bounds for the whole timeline, from the beginning of the
    // first month mentioned until the most recent entry
    static globalTimeBounds(entries) {
        const earliestTimestamp = Math.min(...entries.map(entry => entry.timestamp));
        const latestTimestamp = Math.max(...entries.map(entry => entry.timestamp));

        return {
            min : moment(earliestTimestamp, "x").startOf("month").valueOf(),
            max : latestTimestamp,
        };
    }

    // Generate an descending array of months between timestamps max and min
    // We shift these forward 1 month because our markers label the preceeding month
    // Related: see TimelineMonthMarker
    static months(min, max) {
        let month = moment(max, "x").startOf("month").add(1, "month");
        let minMonth = moment(min, "x").startOf("month");

        let months = [];
        while(month > minMonth) {
            months.push(moment(month).startOf("month"));
            month.subtract(1, "month");
        }

        return months;
    }

    static monthMarkerTop(month, timeBounds) {
        if(TimelineCalcs.isFirstMonth(month, timeBounds)) {
            return this.firstMonthOffset;
        } else {
            return this.timestampToY(month.valueOf(), timeBounds);
        }
    }

    static isFirstMonth(month, timeBounds) {
        const firstMonth = moment(timeBounds.max, "x").add(1, "months").startOf("month");
        const currentMonth = month.startOf("month");

        return currentMonth.isSame(firstMonth);
    }

    // Returns the y-coordinate for the middle of the day that an entry occurred on.
    // This makes all the entries line-up nicely
    static getEntryPosition(entry, timeBounds) {
        if(!entry) return 0;
        const middleOfTheDay = moment(entry.timestamp, "x").startOf("day").add(12, "hours").valueOf();
        return this.timestampToY(middleOfTheDay, timeBounds);
    }

    // Returns the total distance between two entries on the timeline
    // Used for rendering theline behind entries
    static getProjectHeight(project, timeBounds) {
        if(!project || !project.entries || project.entries.length < 2) return 0;
        const firstEntryPos = this.getEntryPosition(project.entries[0], timeBounds);
        const finalEntryPos = this.getEntryPosition(project.entries[project.entries.length - 1], timeBounds);
        return Math.abs(firstEntryPos - finalEntryPos);
    }
}
