import React, {useEffect, useContext} from 'react'

import {useDropzone} from 'react-dropzone';
import moment from 'moment'

import StorageContext from '../../StorageContext'

const AddHeroImage = ({fieldName, value, setFieldValue, error}) => {
    const {storage} = useContext(StorageContext);

    useEffect(() => {
        if(!value || !value.uploadStatus) setFieldValue(`${fieldName}.uploadStatus`, {status: "inactive", progress: 0, error: "", message: ""});
    }, [fieldName, setFieldValue, value])

    const startUpload = files => {

        let oldRef = null;
        if(value.url !== "") {
            //if there was already an image uploaded, hang on to its ref and delete it when the new image is successfully uploaded
            oldRef = value.ref;
        }

        const refString = `${moment().format("YYYY/MM/DD")}/${files[0].name}`;
        setFieldValue(`${fieldName}.ref`, refString);
        setFieldValue(`${fieldName}.name`, files[0].name);
        setFieldValue(`${fieldName}.size`, files[0].size);
        setFieldValue(`${fieldName}.type`, files[0].type);

        const uploadTask = storage.ref().child(refString).put(files[0]);
        uploadTask.on('state_changed', snapshot => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setFieldValue(`${fieldName}.uploadStatus`, {status: "uploading", progress, error: "", message: `Uploading: ${progress}%`});
        }, error => {
            setFieldValue(`${fieldName}.uploadStatus`, {status: "failed", progress: 0, error, message: `Upload Failed: ${error}`});
        }, () => {
            setFieldValue(`${fieldName}.uploadStatus`, {status: "fetch-url", progress: 100, error: "", message: `Uploading: 100%`});
            //don't delete the image if the ref hasn't changed!
            if(oldRef && oldRef !== refString) {
                storage.ref().child(oldRef).delete().then(() => {
                    uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                        setFieldValue(`${fieldName}.uploadStatus`, {status: "complete", progress: 100, error: "", message: `Upload Complete`});
                        setFieldValue(`${fieldName}.url`, downloadURL);
                    });
                }).catch(err => {
                    console.log("Image deletion failed", err);
                })
            } else {
                uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                    setFieldValue(`${fieldName}.uploadStatus`, {status: "complete", progress: 100, error: "", message: `Upload Complete`});
                    setFieldValue(`${fieldName}.url`, downloadURL);
                });
            }
        });
    }
    const rejectUpload = (rejections) => {
        console.log(rejections);
        setFieldValue(`${fieldName}.uploadStatus`, {status: "failed", progress: 0, error: rejections[0].errors[0].message, message: `Error: ${rejections[0].errors[0].message}`});
    }
    const {getRootProps, getInputProps} = useDropzone({
        accept: [
            "image/gif",
            "image/png",
            "image/jpeg",
            "video/mp4"
        ],
        maxSize: 20000000, //20MB
        multiple: false,
        noKeyboard: true,
        preventDropOnDocument: true,
        onDropAccepted: startUpload,
        onDropRejected: rejectUpload
    });

    const remove = () => {
        if(value.uploadStatus.status === "complete") {
            storage.ref().child(value.ref).delete().then(() => {
                setFieldValue(`${fieldName}.ref`, "");
                setFieldValue(`${fieldName}.name`, "");
                setFieldValue(`${fieldName}.size`, 0);
                setFieldValue(`${fieldName}.type`, "");
                setFieldValue(`${fieldName}.url`, "");
            }).catch(err => {
                console.log("Image deletion failed", err);
            });
        } else if(value.uploadStatus.status === "inactive" && value.url !== "") {
            //this means that we're in edit mode, since there's no other way for an inactive image to have a valid URL
            if(window.confirm("Delete media from server?")) {
                storage.ref().child(value.ref).delete().then(() => {
                    setFieldValue(`${fieldName}.ref`, "");
                    setFieldValue(`${fieldName}.name`, "");
                    setFieldValue(`${fieldName}.size`, 0);
                    setFieldValue(`${fieldName}.type`, "");
                    setFieldValue(`${fieldName}.url`, "");
                }).catch(err => {
                    console.log("Image deletion failed", err);
                });
            }
        }
    }

    if(!value || !value.uploadStatus) return null;

    return (
        <div className="form-element images">
            <div className="images-wrapper">
                <span className="label">Hero Image</span>
                <div className="images-container">
                    <div className="image-item-single">
                        <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <p>{!value.name || value.name === "" 
                                ? "Drag + drop a file, or click to select one" 
                                : `${value.name} (${(value.size / (value.size > 1000000 ? 1000000 : 1000)).toFixed(1)} ${(value.size > 1000000 ? "MB" : "kB")})`}
                            </p>
                        </div>
                        <div className="upload-progress-container">
                            <div className="upload-progress-bar" style={{width: `${value.uploadStatus.progress}%`}}></div>
                            <p>{value.uploadStatus.message}</p>
                        </div>
                        <button 
                            type="button" 
                            className="cancel" 
                            onClick={remove}
                            disabled={value.uploadStatus.status === "uploading"}>
                                X
                        </button>
                    </div>
                    {error !== undefined && error.touched !== undefined && error.error !== undefined && error.error.url !== undefined && error.error.url !== ""
                        ? <p className="error">{error.error.url}</p>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}

export default AddHeroImage;