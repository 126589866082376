import React, {useState, useContext, useEffect} from 'react'
import {Redirect, Prompt} from 'react-router-dom'

import {Formik, FieldArray} from 'formik';
import {Form, Autocomplete, Datepicker, SubmitBtn, Input, Textarea, Button} from 'react-formik-ui'
import moment from 'moment';
import * as yup from 'yup'

import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import ReactMarkdown from 'react-markdown'

import AddFileItem from './AddFileItem'

import WeeklogUtils from '../../shared/WeeklogUtils'
import DataContext from '../../DataContext'
import UserContext from '../../UserContext'

import FirebaseConfig from'../../FirebaseConfig'

import '../../css/form.css'

const EditDownload = ({match}) => {

    const {user} = useContext(UserContext);
    const {data, forceUpdateData} = useContext(DataContext);

    const [initialValues, setInitialValues] = useState({
        project: false,
        name: "",
        timestamp: moment().toDate(),
        content: "",
        version: "",
        files: []
    });
    const [download, setDownload] = useState(null);
    const [suggestions, setSuggestions] = useState({});
    const [editComplete, setEditComplete] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isUndeleting, setIsUndeleting] = useState(false);
    const [isShowingPreview, setIsShowingPreview] = useState(false);

    useEffect(() => {
        setSuggestions(data.projects.map(project => project.name));
    }, [data.projects]);

    useEffect(() => {
        const rawDownload = data.downloads.find(d => d.id === match.params.did);
        if(rawDownload === null) {
            setDownload(null);
        } else {
            setDownload({...rawDownload, project: data.projects[rawDownload.project.index]});
        }
    }, [data.downloads, data.projects, match.params.did]);

    useEffect(() => {
        if(download) {
            setInitialValues({
                project: download.project.name,
                name: download.name,
                timestamp: moment(download.timestamp, "x").toDate(),
                content: download.content,
                version: download.version,
                files: download.files
            });
        }
    }, [download])

    const handleSubmit = (values, {setSubmitting}) => {

        const doEditDownload = async(data, oldProjectId, newProjectId, callback) => {

            firebase.auth().currentUser.getIdToken(true).then(idToken => {
                const patchDownload = async () => {
                    await axios.patch(`${FirebaseConfig.databaseURL}/downloads/${download.id}.json?auth=${idToken}`, data);
                    if(oldProjectId !== newProjectId) {
                        await axios.patch(`${FirebaseConfig.databaseURL}/projects/${newProjectId}/downloads.json?auth=${idToken}`, {[download.id] : true});
                        await axios.delete(`${FirebaseConfig.databaseURL}/projects/${oldProjectId}/downloads/${download.id}.json?auth=${idToken}`);
                    }
                    setEditComplete(true);
                    forceUpdateData();
                }
                
                patchDownload();
            }).catch(err => {
                console.log("Error submitting", err);
                callback();
            })
        }

        setSubmitting(true);

        const originalTime = moment(download.timestamp, "x");
        const finalTimestamp = moment(values.timestamp).startOf("day")
            .add(originalTime.hour(), "hours")
            .add(originalTime.minute(), "minutes")
            .add(originalTime.second(), "seconds").valueOf();

        const projectIndex = data.projects.findIndex(project => project.name === values.project);
        if(projectIndex === -1) {
            console.log("Invalid project, somehow?");
            setSubmitting(false);
            return;
        }

        const newDownloadData = {
            name: values.name,
            slug: WeeklogUtils.getUniqueSlug(data, values.name, data.projects[projectIndex], download.id),
            project: data.projects[projectIndex].id,
            timestamp: finalTimestamp,
            content: values.content,
            files: values.files.map(file => {
                return {
                    name: file.name,
                    size: file.size,
                    ref: file.ref,
                    url: file.url
                }
            }),
            version: values.version
        }

        doEditDownload(newDownloadData, download.project.id, data.projects[projectIndex].id, () => setSubmitting(false));
    }

    const handleDelete = () => {
        const doDeleteDownload = async(callback) => {
            firebase.auth().currentUser.getIdToken(true).then(idToken => {
                const deleteDownload = async () => {
                    await axios.patch(`${FirebaseConfig.databaseURL}/downloads/${download.id}.json?auth=${idToken}`, {isTrashed: true});
                    setEditComplete(true);
                    forceUpdateData();
                }
                
                deleteDownload();
            }).catch(err => {
                console.log("Error submitting", err);
                callback();
            })
        }

        setIsDeleting(true);
        doDeleteDownload(() => setIsDeleting(false));
    }

    const handleUndelete = () => {
        const doUndeleteDownload = async(callback) => {
            firebase.auth().currentUser.getIdToken(true).then(idToken => {
                const undeleteDownload = async () => {
                    await axios.patch(`${FirebaseConfig.databaseURL}/downloads/${download.id}.json?auth=${idToken}`, {isTrashed: false});
                    setEditComplete(true);
                    forceUpdateData();
                }
                
                undeleteDownload();
            }).catch(err => {
                console.log("Error submitting", err);
                callback();
            })
        }

        setIsUndeleting(true);
        doUndeleteDownload(() => setIsUndeleting(false));
    }

    const getSchema = () => {
        return yup.object().shape({
            project: yup.string().required("A project is required"),
            name: yup.string().required("A name is required"),
            content: yup.string().notRequired(),
            timestamp: yup.date().required("A date is required"),
            version: yup.string().notRequired(),
            files: yup.array().required("At least one file is required").min(1, "At least one file is required")
        });
    }

    if(!user) return <Redirect to={`${process.env.PUBLIC_URL}/`} />
    if(download === null) return <div><h1>Download not found!</h1></div> //TODO make a nice 404 page or something
    if(editComplete) return <Redirect to={`${process.env.PUBLIC_URL}/dashboard/project/${download.project.id}/downloads`} />

    if(isDeleting) {
        return (
            <div className="container">
                <h1>Edit Download</h1>
                <p>Trashing Download...</p>
            </div>
        )
    }

    if(isUndeleting) {
        return (
            <div className="container">
                <h1>Edit Download</h1>
                <p>Removing Download from Trash...</p>
            </div>
        )
    }

    return (
        <div className="container">
            <h1>Edit Download</h1>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={getSchema}
                onSubmit={handleSubmit}
            >{({values, dirty, isSubmitting, setFieldValue}) => (
                <Form>
                    <Prompt when={dirty} message="You have unsaved changes. If you leave now, those changes will be lost." />
                    <Autocomplete name="project" label="Project" suggestions={suggestions} autoComplete="off" />
                    <Input name="name" label="Name" autoComplete="off" />
                    <Datepicker name="timestamp" label="Date" />
                    <Input name="version" label="Version" autoComplete="off" />
                    <div className="content-previewable-wrapper">
                        <div className="content-previewable-label">
                            <span>Content</span>
                            <button type="button" onClick={() => setIsShowingPreview(!isShowingPreview)}>{isShowingPreview ? "Hide Preview" : "Show Preview"}</button>
                        </div>
                        {isShowingPreview ? <div className="content-preview"><ReactMarkdown source={values.content} /></div> : <Textarea name="content" label="" autoComplete="off" /> }
                    </div>
                    <FieldArray name="files" render={arrayHelpers => { return (
                        <div className="form-element images">
                            <div className="images-wrapper">
                                <span className="label">Files</span>
                                <div className="images-container">
                                {values.files && values.files.length > 0 ? (
                                    values.files.map((file, index) => { return (
                                        <AddFileItem key={"file-" + index} fileArrayName="files" index={index} arrayHelpers={arrayHelpers} values={values} setFieldValue={setFieldValue} />
                                    )})
                                ) : (
                                    null
                                )}
                                <div className="add-image-container">
                                    <button type="button" onClick={() => arrayHelpers.push({url: "", caption: ""})}>Add a File</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    )}} />
                    <div className="form-element">
                        <div className="buttons-wrapper">   
                            <SubmitBtn disabled={isSubmitting || isDeleting} children={isSubmitting ? "Updating..." : "Update"} />
                            {download.isTrashed 
                            ? <Button 
                                disabled={isSubmitting} 
                                className="button-danger" 
                                type="button" 
                                onClick={() => { if(window.confirm("Confirm remove download from trash?")) handleUndelete(); }} 
                                children="Un-Trash Download" />
                            : <Button 
                                disabled={isSubmitting} 
                                className="button-danger" 
                                type="button" 
                                onClick={() => { if(window.confirm("Confirm trash download?")) handleDelete(); }} 
                                children="Trash Download" />
                            }
                        </div>
                    </div>
                </Form>
            )}</Formik>
        </div>
    )
}

export default EditDownload;