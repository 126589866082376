// When entries, efforts or downloads has been changed in a way that modifies indexes
// (eg. removing a hidden project), this function will return the projects with the
// indexes to each of these resources updated.
const reindexProject = (project, ideas, entries, efforts, downloads) => {
    return ({
        ...project,
        idea: {...project.idea, index: ideas.findIndex(ideaIndex => ideaIndex.id === project.idea.id)},
        entries: project.entries.map(entry =>
            ({...entry, index: entries.findIndex(entryIndex => entryIndex.id === entry.id)})
        ),
        efforts: project.efforts.map(effort =>
            ({...effort, index: efforts.findIndex(effortIndex => effortIndex.id === effort.id)})
        ),
        downloads: project.downloads.map(download =>
            ({...download, index: downloads.findIndex(downloadIndex => downloadIndex.id === download.id)})
        ),
    });
};

// When projects has been changed in a way that modifies indexes (eg. removing a hidden
// project), this function will return a resource (entries, efforts, or downloads) with
// the project indexes updated.
const reindexResource = (resources, projects) =>
      resources.map(resource => {
          // Before indexing, the project ID is stored in resource.project, but
          // is moved to resource.project.id afterwards
          const projectId = typeof resource.project == "string" ? resource.project : resource.project.id;

          return {
              ...resource,
              project: {
                  id: projectId,
                  index: projects.findIndex(project => project.id === projectId),
              },
          };
      });


// When an idea is first loaded, its projects take the form:
// - {xyz: true, ...}
// Once processed, we store them as:
// - [{id: "xyz", index: 0}]
// Return the project IDs from either of these structures
const ideaProjectIds = (idea) => {
    if(Array.isArray(idea.projects)) {
        return idea.projects.map(project => project.id);
    } else {
        return Object.keys(idea.projects);
    }
};

// When projects has been changed in a way that modifies indexes (eg. removing a hidden
// project), this function will return ideas with the project indexes updated.
const reindexIdeas = (ideas, projects) =>
      ideas.map(idea => {
          return {
              ...idea,
              projects: ideaProjectIds(idea).map(projectId => ({
                  id: projectId,
                  index: projects.findIndex(project => project.id === projectId),
              })).filter(project => project.index >= 0),
          };
      });

export { reindexProject, reindexResource, reindexIdeas };
