import React, {useState, useEffect, useContext} from 'react'

import Fuse from 'fuse.js'

import SearchResult from './SearchResult'
import DataContext from '../../DataContext'

import './css/search.css'

const Search = () => {
    const [searchData, setSearchData] = useState({search:""});

    const [fuse, setFuse] = useState(null);

    const {data} = useContext(DataContext);

    const [fuseData, setFuseData] = useState([]);

    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        setFuse(new Fuse([], {
            keys: ["searchData"],
            includeScore: true,
            includeMatches: true,
            minMatchCharLength: 3,
            ignoreLocation: true,
            threshold: 0.6
        }));
    }, [])

    useEffect(() => {
        setFuseData(data.projects.map(project => {
            return {name: project.name, searchData: project.name, link: `${process.env.PUBLIC_URL}/projects/${project.slug}`, type:"Project"}
        }).concat(data.entries.map(entry => {
            return {name: entry.summary, searchData: entry.summary, link: `${process.env.PUBLIC_URL}/entries/${entry.slug}`, type:"Entry", project:data.projects[entry.project.index]}
        })).concat(data.entries.map(entry => {
            return {name: entry.summary, searchData: entry.content, link: `${process.env.PUBLIC_URL}/entries/${entry.slug}`, type:"Entry", project:data.projects[entry.project.index]}
        })));

    }, [data.projects, data.entries]);

    useEffect(() => {
        if(fuse) {
            fuse.setCollection(fuseData);
        }
    }, [fuseData, fuse])

    const clearSearchResults = (e) => {
        setSearchData({search: ""});
        setSearchResults([]);
    }

    const handleBlur = e => {
        clearSearchResults();
    }

    const handleChange = e => {
        setSearchData({...searchData, [e.target.id]:e.target.value});
        if(e.target.value.length <= 2) {
            setSearchResults([]);
            return;
        }

        const results = fuse.search(e.target.value);
        setSearchResults(results.slice(0, 10).filter(result => result.score < 0.4).map(result => {
            return <SearchResult key={result.refIndex} match={result} handleClick={clearSearchResults}/>
        }))
    }

    return (
        <div className="search-form">
            <input className="search-input" type="text" id="search" onChange={handleChange} value={searchData.search} autoComplete="off" placeholder="Search..." onBlur={handleBlur} />
            {searchResults && searchResults.length > 0 ? (
                <div className="search-results">
                    {searchResults}
                </div>
            ) : searchData.search.length <= 2 ? null : (
                <div className="search-results">
                    <div className="search-result">
                        <h2 >No search results found</h2>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Search;