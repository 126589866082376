import React, {useState, useEffect} from 'react'

import moment from 'moment'

import WeeklogUtils from '../../shared/WeeklogUtils'

const EntryEffortIndicator = ({effort, entryMoment}) => {

    const [mode, setMode] = useState("");

    useEffect(() => {
        const start = moment(effort.startDate, "x").startOf("day");
        const startDay = start.year() * 365 + start.dayOfYear();
        const entryDay = entryMoment.year() * 365 + entryMoment.dayOfYear();
        if(!effort.endDate) {
            setMode(startDay === entryDay ? "start" : "");
        } else {
            const end = moment(effort.endDate, "x").startOf("day");
            const endDay = end.year() * 365 + end.dayOfYear();

            if(startDay === entryDay) setMode(endDay === entryDay ? "start-finish" : "start");
            else setMode(endDay === entryDay ? "finish" : "");
        }
    }, [effort, entryMoment])

    if(!effort) return null;
    if(mode === "") return null;

    return (
        <div className="entry-effort-indicator" style={{
            borderColor: WeeklogUtils.getStatusColor(effort.type), 
            color: WeeklogUtils.getStatusColor(effort.type),
            backgroundColor: WeeklogUtils.getRgbString(WeeklogUtils.getStatusColor(effort.type), 0.05),
        }}>
            <div>
                <p>Development Effort "{effort.summary}" {mode === "start" ? "Begins" : (mode === "finish" ? "Ends" : "Begins and Ends")}</p>
                <p>Effort Type: {WeeklogUtils.capitalize(effort.type)}</p>
                {mode === "start" ? null : <p>New Version Number: {effort.newVersionNumber}</p>}
            </div>
        </div>
    )
}

export default EntryEffortIndicator