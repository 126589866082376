import React from 'react'
import {Link} from 'react-router-dom'

const DashboardProjectNav = ({project, page}) => {
    return (
        <nav className="project-detail-nav">
            <div className="container">
                <ul>
                    <li style={!page || page === "entries" ? {backgroundColor: "#111"} : null}>
                        <Link to={`${process.env.PUBLIC_URL}/dashboard/project/${project.id}/`}>Entries</Link>
                    </li>
                    <li style={page === "efforts" ? {backgroundColor: "#111"} : null}>
                        <Link to={`${process.env.PUBLIC_URL}/dashboard/project/${project.id}/efforts`}>Efforts</Link>
                    </li>
                    <li style={page === "downloads" ? {backgroundColor: "#111"} : null}>
                        <Link to={`${process.env.PUBLIC_URL}/dashboard/project/${project.id}/downloads`}>Downloads</Link>
                    </li>
                    <li style={page === "edit" ? {backgroundColor: "#111"} : null}>
                        <Link to={`${process.env.PUBLIC_URL}/dashboard/project/${project.id}/edit`}>Edit</Link>
                    </li>
                    <li style={{marginLeft: "5em"}}>
                        <Link to={`${process.env.PUBLIC_URL}/projects/${project.slug}`}>View</Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default DashboardProjectNav;