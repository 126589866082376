import React, {useContext} from 'react'
import {Redirect} from 'react-router-dom'

import {Formik} from 'formik';
import {Form, SubmitBtn, Input} from 'react-formik-ui'
import * as yup from 'yup'

import firebase from 'firebase/app'
import 'firebase/auth'

import DataContext from '../../DataContext'
import UserContext from '../../UserContext'

import '../../css/form.css'

const Login = () => {

    const {data} = useContext(DataContext);
    const {user} = useContext(UserContext);

    const handleSubmit = (values, {setSubmitting, setErrors}) => {

        const doSignin = async(data, callback) => {
            firebase.auth().signInWithEmailAndPassword(data.email, data.password).then(() => {
                callback();
            }).catch(err => {
                console.log(err.message);
                setErrors({password:"Incorrect username/password combination"});
                callback();
            });
        }

        setSubmitting(true);

        const foundUser = data.users.find(user => user.username === values.username);
        const email = foundUser ? foundUser.email : null;

        if(email === null) {
            console.log("Email was null somehow?");
            setSubmitting(false);
            return;
        }

        doSignin({email, password:values.password}, () => setSubmitting(false));
    }

    const getSchema = () => {
        return yup.object().shape({
            username: yup.string().required("Enter your username").test('username', "Username not found", username => data.users.findIndex(user => user.username === username) !== -1),
            password: yup.string().required("Enter your password")
        });
    }

    if(user !== null) {
        return <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />
    }

    return (
        <div className="container">
            <h1>Login</h1>
            <Formik
                initialValues={{
                    username: "",
                    password: ""
                }}
                validationSchema={getSchema}
                onSubmit={handleSubmit}
            >{({values, dirty, isSubmitting}) => (
                <Form>
                    <Input name="username" label="Username" autoComplete="off" />
                    <Input name="password" label="Password" autoComplete="off" type="password" />
                    <div className="form-element">
                        <div className="buttons-wrapper">   
                            <SubmitBtn disabled={isSubmitting} children={isSubmitting ? "Logging In..." : "Login"} />
                        </div>
                    </div>
                </Form>
            )}</Formik>
        </div>
    )
}

export default Login;