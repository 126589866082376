import React, {useState, useEffect, useCallback} from 'react'

import { useFormikContext } from 'formik'
import {Autocomplete} from 'react-formik-ui'

import './css/add-tags.css'

const AddTags = ({validTags}) => {

    const {values, setFieldValue} = useFormikContext();
    const [nextTagId, setNextTagId] = useState(0);
    const [tagDisplayItems, setTagDisplayItems] = useState([]);

    const tryAddTag = () => {
        if(!values.tagEntry || values.tagEntry === "") return;
        if(
            values.tags && 
            values.tags.length > 0 && 
            values.tags.find(tag => tag.name.toLowerCase() === values.tagEntry.toLowerCase())
        ) return;

        setFieldValue("tags", [...values.tags, {
            id: nextTagId,
            name: values.tagEntry.toLowerCase(),
            existing: validTags.findIndex(tag => tag.toLowerCase() === values.tagEntry) !== -1
        }]);
        setFieldValue("tagEntry", "");
        setNextTagId(nextTagId + 1);
    }

    const deleteTag = useCallback(id => {
        setFieldValue("tags", values.tags.filter(tag => tag.id !== id));
    }, [values.tags, setFieldValue]);
    
    useEffect(() => {
        if(!values.tags) return;

        if(values.tags.length > 0 && values.tags[0].id === undefined) {
            //this means that we are dealing with the raw database, so we need to map it accordingly and update
            setNextTagId(values.tags.length);
            setFieldValue("tags", values.tags.map((item, id) => {
                return {
                    name: item,
                    id,
                    existing: true
                }
            }));
        } else {
            setTagDisplayItems(values.tags.map(tag => {
                return (
                    <p className="tag-item" key={`tag-${tag.id}`}>
                        <span>{tag.name}{tag.existing ? null : " (new)"}</span>
                        <span onClick={() => deleteTag(tag.id)}>x</span>
                    </p>
                )
            }))
        }
    }, [values.tags, deleteTag, setFieldValue])

    return (
        <div className="tags-wrapper">
            <Autocomplete name="tagEntry" label="Tags" suggestions={validTags} autoComplete="off" />
            <div className="tags-display">{tagDisplayItems && tagDisplayItems.length > 0 ? tagDisplayItems : <p>No Tags</p>}</div>
            <button className="hover-tile" type="button" onClick={tryAddTag}>Add Tag</button>
        </div>
    )
}

export default AddTags;