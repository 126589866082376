import React, {useState, useEffect} from 'react'

import GalleryImage from './GalleryImage'

import './css/image-gallery.css'

const ImageGallery = ({images, width}) => {
    const [activeIndex, updateActiveIndex] = useState(0);
    const [imageList, updateImageList] = useState([]);

    useEffect(() => {
        updateActiveIndex(0);
    }, [images]);

    useEffect(() => {
        updateImageList(images.map((image, index) => {
            return (
                <div key={index}>
                    <GalleryImage 
                        image={image} 
                        width={width}
                        active={false} 
                        selected={index === activeIndex}
                        index={index}
                        setActive={(index) => {
                            updateActiveIndex(index);
                        }} />
                </div>
            )
        }));
    }, [images, width, activeIndex])

    if(images === null || images.length === 0) return <div className="image-gallery-container"></div>
    if(images[activeIndex] === undefined) return <div className="image-gallery-container"></div>

    return (
        <div className="image-gallery-container">
            <GalleryImage image={images[activeIndex]} width={width} active={true} />
            {images.length <= 1 ? null : (
                <div className="image-gallery">
                    {imageList}
                </div>
            )}
            
        </div>
    )
}

export default ImageGallery;