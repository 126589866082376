import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

const ProjectDetailNav = ({project, page}) => {

    const [selectedPage, setSelectedPage] = useState("overview");
    useEffect(() => {
        if(!page) {
            setSelectedPage(project.content && project.content !== "" ? "overview" : "entries");
        } else {
            setSelectedPage(page);
        }
    }, [page, project.content])
    const [imageCount, setImageCount] = useState(0);
    useEffect(() => {
        const featuredCount = project.featuredImages ? project.featuredImages.length : 0;
        const entryImageCount = !project.entries || project.entries.length === 0 
            ? 0 
            : project.entries.reduce((acc, entry) => {
                return !entry.images || entry.images.length === 0 
                    ? acc 
                    : acc + entry.images.length;
            }, 0)
        setImageCount(featuredCount + entryImageCount);
    }, [project.entries, project.featuredImages])

    return (
        <nav className="project-detail-nav">
            <div className="container">
                <ul>
                    { !project.content || project.content === "" ? null : (
                        <li className={selectedPage === "overview" ? "nav-selected" : null}>
                            <Link to={`${process.env.PUBLIC_URL}/projects/${project.slug}`}>Overview</Link>
                        </li>
                    )}
                    { !project.entries || project.entries.length === 0 ? null : (
                        <li className={selectedPage === "entries" ? "nav-selected" : null}>
                            <Link to={`${process.env.PUBLIC_URL}/projects/${project.slug}/entries`}>Entries</Link>
                        </li>
                    )}
                    { !project.entries || project.entries.length === 0 || imageCount === 0 ? null : (
                        <li className={selectedPage === "gallery" ? "nav-selected" : null}>
                            <Link to={`${process.env.PUBLIC_URL}/projects/${project.slug}/gallery`}>Gallery</Link>
                        </li>
                    )}
                    { !project.downloads || project.downloads.length === 0 ? null : (
                        <li className={selectedPage === "downloads" ? "nav-selected" : null}>
                            <Link to={`${process.env.PUBLIC_URL}/projects/${project.slug}/downloads`}>Downloads</Link>
                        </li>
                    )}
                </ul>
            </div>
        </nav>
    )
}

export default ProjectDetailNav;