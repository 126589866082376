import React, {useState, useEffect, useContext, useCallback} from 'react'
import {Redirect} from 'react-router-dom'

import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'

import DataContext from '../../DataContext'
import UserContext from '../../UserContext'

import EntryLabeller from './EntryLabeller'
import WeeklogUtils from '../../shared/WeeklogUtils'
import FirebaseConfig from'../../FirebaseConfig'

import './css/label-entries.css'

const Entries = () => {
    const {data} = useContext(DataContext);
    const {user} = useContext(UserContext);
    
    const [entries, setEntries] = useState([]);
    const [loading, setLoading] = useState({});
    const [labelled, setLabelled] = useState({});

    const handleClicked = useCallback((entry, type) => {
        const setEntryType = async (entry, type) => {
            setLoading({...loading, [entry.id]: true});
            const idToken = await firebase.auth().currentUser.getIdToken(true);
            if(!idToken) {
                console.error("Invalid ID Token");
                setLoading({...loading, [entry.id]: false});
                return;
            }
            
            try {
                const response = await axios.patch(`${FirebaseConfig.databaseURL}/entries/${entry.id}.json?auth=${idToken}`, {label: type});
                console.log("Entry updated successfully", response.data);
                setLabelled({...labelled, [entry.id]: true});
                setLoading({...loading, [entry.id]: false});
            } catch(error) {
                console.error("Failed to update entry " + entry.summary, error);
                setLoading({...loading, [entry.id]: false});
            }
    
        }

        console.log("Entry " + entry.summary + " set to type " + type);
        setEntryType(entry, type);
    }, [loading, labelled]);

    useEffect(() => {
        if(!data.entries || data.entries.length === 0) return;
        setEntries([
                ...data.entries
                    .filter(entry => WeeklogUtils.getEntryEffort(entry, data.projects[entry.project.index].efforts) === null)
                    .filter(entry => !entry.label)
                    .filter(entry => !labelled[entry.id]), 
                ...data.entries
                    .filter(entry => !entry.label || entry.label === "stabilization")
                    .filter(entry => !labelled[entry.id])
            ]
            .map(entry => <EntryLabeller 
                                key={entry.id} 
                                entry={{...entry, project: data.projects[entry.project.index]}} 
                                onClicked={handleClicked} 
                                loading={loading[entry.id] ? true : false}
                            />)
        );
    }, [data.entries, data.projects, loading, labelled, handleClicked])

    if(!user) return <Redirect to={`${process.env.PUBLIC_URL}/`} />

    return (
        <div className="container">
            <h2>Don't worry, only {entries.length} entries to go!</h2>
            {entries}
            {

            }
        </div>
    )
}

export default Entries;