import React, {useState, useMemo, useEffect} from 'react'
import {useHistory} from 'react-router-dom'

import moment from 'moment'
import { useTable, useSortBy } from 'react-table'

import WeeklogUtils from '../../shared/WeeklogUtils'

const DashboardProjectTable = ({projects}) => {
    const [data, setData] = useState([]);

    const statusSort = (a, b, colId, desc) => {
        const statusToSortVal = status => {
            if(status === "creation") return 0;
            if(status === "elaboration") return 1;
            if(status === "preparation") return 2;
            if(status === "experimentation") return 3;
            if(status === "stabilization") return 4;
            if(status === "experiment") return 5;
            if(status === "prototype") return 6;
            if(status === "prerelease") return 7;
            if(status === "release") return 8;
            if(status === "concept") return 9;
            return 10;
        };

        const aVal = statusToSortVal(a.values[colId]);
        const bVal = statusToSortVal(b.values[colId]);

        const output = aVal === bVal 
            ? 0 
            : aVal > bVal ? 1 : -1;

        return output;
    }

    const columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Status',
            accessor: 'status',
            sortType: statusSort
        },
        {
            Header: 'Visibility',
            accessor: 'visibility',
        },
        {
            Header: 'Updated',
            accessor: 'lastUpdated',
            Cell: ({value}) => {
                if(moment().diff(moment(value, "x"), "days") > 30)
                    return moment(value, "x").format(window.innerWidth > 640 ? "Do MMMM YYYY" : "DD/MM/YY")
                else
                    return moment(value, "x").fromNow();
            }
        },
        {
            Header: 'id',
            accessor: 'id'
        }
    ], []);
    
    useEffect(() => {
        setData(projects.map(project => {
            return {
                name: project.name,
                status: project.status,
                lastUpdated: WeeklogUtils.getProjectLastUpdatedTimestamp(project),
                id: project.id,
                visibility: project.visibility
            }
        }))
    }, [projects]);
    const history = useHistory();

    const navigateToProject = id => {
        history.push(`${process.env.PUBLIC_URL}/dashboard/project/${id}`);
    }
    

    return <Table columns={columns} data={data} onRowClick={navigateToProject} />;
}

const Table = ({columns, data, onRowClick}) => {
    const tableInstance = useTable({
        columns, 
        data, 
        initialState: {
            hiddenColumns: window.innerWidth > 640 ? ["id"] : ["id", "visibility"],
            sortBy: [
                {
                    id: 'lastUpdated',
                    desc: true
                }
            ]
        }
    }, useSortBy);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    if(!data || data.length === 0) return null;

    return (
        <table {...getTableProps()} className="dashboard-project-table">
            <thead>
            {// Loop over the header rows
            headerGroups.map(headerGroup => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                headerGroup.headers.map(column => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        <span>
                            {column.isSorted 
                                ? column.isSortedDesc ? "▼" : "▲"
                                : "▶"
                            }
                        </span>
                    </th>
                ))}
                </tr>
            ))}
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
            {// Loop over the table rows
            rows.map(row => {
                // Prepare the row for display
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()} onClick={() => onRowClick(row.values.id)} style={{
                        color: WeeklogUtils.getStatusColor(row.values.status)
                    }}>
                        {// Loop over the rows cells
                        row.cells.map(cell => {
                        // Apply the cell props
                        return (
                            <td {...cell.getCellProps()}>
                            {// Render the cell contents
                            cell.render('Cell')}
                            </td>
                        )
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

export default DashboardProjectTable;