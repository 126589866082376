import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

import moment from 'moment'
import ReactMarkdown from 'react-markdown'

const ProjectDetailDownloads = ({project}) => {

    const [downloads, setDownloads] = useState([]);

    useEffect(() => {
        if(!project) return;

        if(project.downloads.length > 0) {
            setDownloads(project.downloads.map(download => {
                return (
                    <div className="download" key={download.slug}>
                        <h1>
                            <Link to={`${process.env.PUBLIC_URL}/downloads/${download.slug}`}>Version {download.version}</Link>
                            <Link className="edit-button" to={`${process.env.PUBLIC_URL}/downloads/edit/${download.id}`}>✎</Link>
                        </h1>
                        <p>{moment(download.timestamp, "x").format("Do MMMM YYYY")}</p>
                        <div>
                            <h4>{download.name}</h4>
                            <ReactMarkdown source={download.content} />
                        </div>
                        <div className="files">
                            <h3>Files</h3>
                            <ul>
                                {download.files.map((file, index) => {
                                    return <li key={index}><a download={file.url} href={file.url} >
                                        <p>{file.name}</p>
                                        <p>{`${(file.size / (file.size > 1000000 ? 1000000 : 1000)).toFixed(1)} ${(file.size > 1000000 ? "MB" : "kB")}`}</p>
                                    </a></li>
                                })}
                            </ul>
                        </div>
                    </div>
                );
            }))
        }
    }, [project])

    if(!project || !project.downloads || project.downloads.length === 0) {
        return (
            <div>
                <h3>No downloads found</h3>
                <p>It looks like there haven't been any downloads created for this project yet!</p>
            </div>
        )
    }

    return (
        <div>
            {downloads}
        </div>
    )
}

export default ProjectDetailDownloads;