import React, {useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import moment from 'moment'

import DataContext from '../../DataContext'

import EntryListItem from './EntryListItem'

const EntryDay = ({entries}) => {

    const {data} = useContext(DataContext);
    const [projectList, setProjectList] = useState([]);

    useEffect(() => {
        setProjectList([...new Set(entries.map(entry => {
            const project = data.projects[entry.project.index];
            if(!project) console.log("Project was invalid for entry", entry);
            return <Link key={project.id} to={`${process.env.PUBLIC_URL}/projects/${project.slug}`}>{project.name}</Link>
        }))]);
    }, [data.projects, entries]);

    return (
        <div className="entry-day">
            <h3>
                {moment(entries[0].timestamp, "x").format("dddd")}: {projectList}
            </h3>
            <p>{moment(entries[0].timestamp, "x").format("Do MMMM YYYY")}</p>
            { entries.map(entry => {
                return (
                    <EntryListItem key={entry.id} entry={entry} project={data.projects[entry.project.index]} />
                )
            })}
            <hr/>
        </div>
    )
}

export default EntryDay;