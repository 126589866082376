import React, {useState, useEffect, useContext} from 'react'

import DataContext from '../../DataContext'

import ProjectHeader from './ProjectHeader'
import ProjectDetailOverview from './ProjectDetailOverview'
import ProjectDetailEntries from './ProjectDetailEntries'
import ProjectDetailGallery from './ProjectDetailGallery'
import ProjectDetailDownloads from './ProjectDetailDownloads'

import './css/project-detail.css'
import WeeklogUtils from '../../shared/WeeklogUtils'

const ProjectDetail = ({match}) => {

    const [project, updateProject] = useState(null);
    const [page, setPage] = useState(null);

    const {data} = useContext(DataContext);

    useEffect(() => {
        const curProject = data.projects.find(project => project.slug === match.params.pslug);
        updateProject(WeeklogUtils.getPopulatedProject(curProject, data));
    }, [data, match.params.pslug]);

    useEffect(() => {
        if(!project) {
            setPage(null);
            return;
        }

        switch(match.params.page) {
            case "entries":
                setPage(<ProjectDetailEntries project={project} />);
                break;
            case "gallery":
                setPage(<ProjectDetailGallery project={project} />);
                break;
            case "downloads":
                setPage(<ProjectDetailDownloads project={project} />);
                break;
            default:
                setPage(project.content && project.content !== ""
                    ? <ProjectDetailOverview project={project} />
                    : <ProjectDetailEntries project={project} />
                );
                break;
        }
    }, [match.params.page, project])

    if(!project) return <div></div>

    return (
        <ProjectHeader project={project} page={match.params.page}>
            {page}
        </ProjectHeader>
    )
}

export default ProjectDetail;