import React from 'react'
import {Link} from 'react-router-dom'

import TimelineCalcs from './TimelineCalcs'
import "./css/timeline.css"

const TimelineEntry = ({entry, backgroundColor, globalTimeBounds, hoverOn, hoverOff}) => {
    return (
        <Link 
            to={`${process.env.PUBLIC_URL}/entries/${entry.slug}`} 
            className="timeline-entry"
            style={{
                top: `${TimelineCalcs.getEntryPosition(entry, globalTimeBounds)}px`,
                backgroundColor
            }}
            onMouseEnter={e => hoverOn({entry, pos:{x:e.clientX, y:e.clientY}})}
            onMouseLeave={() => hoverOff()}>
        </Link>
    )
}

export default TimelineEntry;