import React, {useEffect, useState} from 'react'

import placeholder from '../../img/placeholder.png'

const LazyImage = (props) => {
    const [imageSrc, setImageSrc] = useState(placeholder)
    const [imageRef, setImageRef] = useState()
    const [imageOpacity, setImageOpacity] = useState(0);
  
    const onLoad = event => {
      event.target.classList.add('loaded')
    }
  
    const onError = event => {
      event.target.classList.add('has-error')
    }
  
    useEffect(() => {
      let observer
      let didCancel = false
  
      if (imageRef && imageSrc !== props.src) {
        if (IntersectionObserver) {
          observer = new IntersectionObserver(
            entries => {
              entries.forEach(entry => {
                if (
                  !didCancel &&
                  (entry.intersectionRatio > 0 || entry.isIntersecting)
                ) {
                  setImageSrc(props.src)
                  setImageOpacity(1.0);
                  observer.unobserve(imageRef)
                }
              })
            },
            {
              threshold: 0.01,
              rootMargin: '75%',
            }
          )
          observer.observe(imageRef)
        } else {
          // Old browsers fallback
          setImageSrc(props.src)
          setImageOpacity(1.0);
        }
      }
      return () => {
        didCancel = true
        // on component cleanup, we remove the listner
        if (observer && observer.unobserve) {
          observer.unobserve(imageRef)
        }
      }
    }, [props.src, imageSrc, imageRef])
    return (
      <img
        {...props}
        ref={setImageRef}
        alt={props.alt || ""}
        src={imageSrc}
        onLoad={onLoad}
        onError={onError}
        style={{opacity:imageOpacity, transition: "opacity 0.5s"}}
      />
    )
  }

export default LazyImage;