import React, {useEffect, useContext} from 'react'

import {useDropzone} from 'react-dropzone';
import {Input} from 'react-formik-ui'
import moment from 'moment'

import StorageContext from '../../StorageContext'

const AddImageItem = ({imageArrayName, index, arrayHelpers, values, setFieldValue}) => {
    const {storage} = useContext(StorageContext);

    useEffect(() => {
        setFieldValue(`${imageArrayName}.${index}.uploadStatus`, {status: "inactive", progress: 0, error: "", message: ""});
    }, [index, setFieldValue, imageArrayName])

    const startUpload = files => {

        let oldRef = null;
        if(values[imageArrayName][index].url !== "") {
            //if there was already an image uploaded, hang on to its ref and delete it when the new image is successfully uploaded
            oldRef = values[imageArrayName][index].ref;
        }

        const refString = `${moment().format("YYYY/MM/DD")}/${files[0].name}`;
        setFieldValue(`${imageArrayName}.${index}.ref`, refString);
        setFieldValue(`${imageArrayName}.${index}.name`, files[0].name);
        setFieldValue(`${imageArrayName}.${index}.size`, files[0].size);
        setFieldValue(`${imageArrayName}.${index}.type`, files[0].type);

        const uploadTask = storage.ref().child(refString).put(files[0]);
        uploadTask.on('state_changed', snapshot => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setFieldValue(`${imageArrayName}.${index}.uploadStatus`, {status: "uploading", progress, error: "", message: `Uploading: ${progress}%`});
        }, error => {
            setFieldValue(`${imageArrayName}.${index}.uploadStatus`, {status: "failed", progress: 0, error, message: `Upload Failed: ${error}`});
        }, () => {
            setFieldValue(`${imageArrayName}.${index}.uploadStatus`, {status: "fetch-url", progress: 100, error: "", message: `Uploading: 100%`});
            //don't delete the image if the ref hasn't changed!
            if(oldRef && oldRef !== refString) {
                storage.ref().child(oldRef).delete().then(() => {
                    uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                        setFieldValue(`${imageArrayName}.${index}.uploadStatus`, {status: "complete", progress: 100, error: "", message: `Upload Complete`});
                        setFieldValue(`${imageArrayName}.${index}.url`, downloadURL);
                    });
                }).catch(err => {
                    console.log("Image deletion failed", err);
                })
            } else {
                uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                    setFieldValue(`${imageArrayName}.${index}.uploadStatus`, {status: "complete", progress: 100, error: "", message: `Upload Complete`});
                    setFieldValue(`${imageArrayName}.${index}.url`, downloadURL);
                });
            }
        });
    }
    const rejectUpload = (rejections) => {
        console.log(rejections);
        setFieldValue(`${imageArrayName}.${index}.uploadStatus`, {status: "failed", progress: 0, error: rejections[0].errors[0].message, message: `Error: ${rejections[0].errors[0].message}`});
    }
    const {getRootProps, getInputProps} = useDropzone({
        accept: [
            "image/gif",
            "image/png",
            "image/jpeg",
            "video/mp4"
        ],
        maxSize: 20000000, //20MB
        multiple: false,
        noKeyboard: true,
        preventDropOnDocument: true,
        onDropAccepted: startUpload,
        onDropRejected: rejectUpload
    });

    const remove = () => {
        if(values[imageArrayName][index].uploadStatus.status === "complete") {
            storage.ref().child(values[imageArrayName][index].ref).delete().then(() => {
                arrayHelpers.remove(index);
            }).catch(err => {
                console.log("Image deletion failed", err);
            });
        } else if(values[imageArrayName][index].uploadStatus.status === "inactive" && values[imageArrayName][index].url !== "") {
            //this means that we're in edit mode, since there's no other way for an inactive image to have a valid URL
            if(window.confirm("Delete media from server?")) {
                storage.ref().child(values[imageArrayName][index].ref).delete().then(() => {
                    arrayHelpers.remove(index);
                }).catch(err => {
                    console.log("Image deletion failed", err);
                });
            }
        } else {
            arrayHelpers.remove(index);
        }
    }

    if(!values[imageArrayName][index].uploadStatus) return null;

    return (
        <div key={index} className="images-item">
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>{!values[imageArrayName][index].name || values[imageArrayName][index].name === "" 
                    ? "Drag + drop a file, or click to select one" 
                    : `${values[imageArrayName][index].name} (${(values[imageArrayName][index].size / (values[imageArrayName][index].size > 1000000 ? 1000000 : 1000)).toFixed(1)} ${(values[imageArrayName][index].size > 1000000 ? "MB" : "kB")})`}
                </p>
            </div>
            <Input type="text" name={`${imageArrayName}.${index}.caption`} placeholder="Caption" autoComplete="off" />
            <div className="upload-progress-container">
                <div className="upload-progress-bar" style={{width: `${values[imageArrayName][index].uploadStatus.progress}%`}}></div>
                <p>{values[imageArrayName][index].uploadStatus.message}</p>
            </div>
            <button 
                type="button" 
                className="cancel" 
                onClick={remove}
                disabled={values[imageArrayName][index].uploadStatus.status === "uploading"}>
                    X
            </button>
            <button 
                type="button" 
                className="up" 
                onClick={() => arrayHelpers.swap(index, index-1)}
                disabled={index === 0}>
                    ▲
            </button>
            <button 
                type="button" 
                className="down" 
                onClick={() => arrayHelpers.swap(index, index+1)}
                disabled={index === values[imageArrayName].length - 1} >
                    ▼
            </button>
        </div>
    )
}

export default AddImageItem;