import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

import moment from 'moment'

import './css/effort-tile.css'

const EffortTile = ({effort, top, left, width, height, pxPerDay}) => {

    const [entryLines, setEntryLines] = useState([]);
    useEffect(() => {
        setEntryLines(effort.entries.map(entry => {
            const entryDate = moment(entry.timestamp, "x");
            const entryOffset = entryDate.diff(moment(effort.endDate, "x"), "days");
            return <div key={`entry_${entry.id}`} style={{
                top: ((effort.endDaysAgo - effort.startDaysAgo) - entryOffset) * pxPerDay - 5
            }}><div></div></div>
        }))
    }, [effort, pxPerDay])



    return (
        <Link to={`${process.env.PUBLIC_URL}/entries/${effort.entries.slice(-1)[0].slug}`}
            className="effort-tile" 
            style={{
                top: `${top}px`,
                left: `${left}%`,
                width: `${width}%`,
                height: `${height}px`,
                backgroundImage: `url('${effort.project.heroImage ? effort.project.heroImage.url : ""}')`,
                borderColor: effort.type === "experimentation" ? "#8A2BE2" : effort.type === "elaboration" ? "#1E90FF" : effort.type === "stabilization" ? "#b22222" : "#FFFFFF"
            }}
        >
            <div className="effort-tile-inner">
                {window.innerWidth < 1000 ? null : (
                <div>
                    <p>
                        {`${effort.project.name} -`}
                    </p>
                    <p>
                        {effort.summary}
                    </p>
                </div>
                )}
            </div>
            <div className="effort-tile-entries">
                {entryLines}
            </div>
        </Link>
    )
}

export default EffortTile;