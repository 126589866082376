import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import moment from 'moment'

import WeeklogUtils from '../../shared/WeeklogUtils'

const ProjectDetailEntries = ({project}) => {
    const [entries, setEntries] = useState([]);

    const [isMobile, setIsMobile] = useState(null);
    React.useEffect(() => {
      function handleResize() {
        setIsMobile(window.innerWidth < 640);
      }
  
      window.addEventListener('resize', handleResize)
      handleResize();
    }, []);

    useEffect(() => {
        if(!project) return;

        let projectHistory = (project.entries && project.entries.length > 0 ? project.entries.map(e => {
            return {
                ...e, 
                itemType:"entry"
            }
        }) : []).concat(!(project.efforts && project.efforts.length > 0) ? [] : project.efforts.map(e => {
            return {
                ...e, 
                itemType:"effort-start", 
                timestamp:moment(e.startDate, "x").startOf("day").valueOf()
            };
        })).concat(!(project.efforts && project.efforts.length > 0) ? [] : project.efforts.filter(e => e.endDate).map(e => {
            return {
                ...e, 
                itemType:"effort-end", 
                timestamp:moment(e.endDate, "x").endOf("day").valueOf()
            };
        })).sort((a, b) => b.timestamp - a.timestamp).filter(item => !item.isTrashed);

        //look for instances where an entry is surrounded by an effort start and end
        //in this situation, we want to strip the effort start and end, and instead
        //just display the new version number on that entry, to clean up the timeline
        //and display projects with lots of discrete one-entry efforts properly
        let finalProjectHistory = [...projectHistory];
        if(projectHistory.length >= 3) {
            projectHistory.forEach((item, i) => {
                if(i >= projectHistory.length - 2) return;

                if(!(projectHistory[i+0].itemType === "effort-end" && 
                     projectHistory[i+1].itemType === "entry" && 
                     projectHistory[i+2].itemType === "effort-start")) return;

                finalProjectHistory = finalProjectHistory
                    .filter(item => item.id !== projectHistory[i].id)
                    .filter(item => item.id !== projectHistory[i + 2].id)
                const targetEntryIndex = finalProjectHistory.findIndex(item => item.id === projectHistory[i + 1].id);
                finalProjectHistory[targetEntryIndex].versionSpan = (
                    <p key={projectHistory[i + 1].id} className="effort-entry" style={{
                        fontWeight: "bold",
                        color: WeeklogUtils.getStatusColor(projectHistory[i].type)
                    }}>New Version: {projectHistory[i].newVersionNumber}</p>
                )
                finalProjectHistory[targetEntryIndex].bgColor = WeeklogUtils.getRgbString(WeeklogUtils.getStatusColor(projectHistory[i].type), 0.05);
            });
        }
        projectHistory = finalProjectHistory;

        //this lets us space out the entries a little
        let entrySpaces = [];
        projectHistory.forEach((historyItem, index) => {
            let space = 0;

            if(index > 0) {
                const lastMoment = moment(projectHistory[index-1].timestamp, "x");
                const curMoment = moment(historyItem.timestamp, "x");
                const dayOffset = (lastMoment.dayOfYear() + 365 * lastMoment.year()) - (curMoment.dayOfYear() + 365 * curMoment.year());
                if(dayOffset < 3) {
                    space = 0;
                } else if(dayOffset < 10) {
                    space = 1;
                } else if(dayOffset < 30) {
                    space = 4;
                } else {
                    space = 8;
                }

                if(index === projectHistory.length - 1) {
                    entrySpaces[index] = [
                        `${space}em`, 
                        `${space + (!isMobile ? (historyItem.versionSpan ? 3.6 : 1.8) : (historyItem.versionSpan ? 3.0 : 1.8))}em`,
                        `-${space}em`
                    ];
                } else {
                    entrySpaces[index] = [
                        `${space}em`, 
                        `${space + (!isMobile ? (historyItem.versionSpan ? 3.6 : 2.4) : (historyItem.versionSpan ? 6.0 : 3.6))}em`,
                        `-${space}em`
                    ];
                }
            } else {
                entrySpaces[index] = [
                    `${space}em`,
                    `${(space + (!isMobile ? (historyItem.versionSpan ? 3.6 : 2.4) : (historyItem.versionSpan ? 6.0 : 3.6))) * 0.5}em`,
                    !isMobile ? (historyItem.versionSpan ? `1.8em` : `1.2em`) : (historyItem.versionSpan ? `3.0em` : `1.8em`)
                ]
            }            
        });

        setEntries(projectHistory.map((historyItem, index) => {
            if(historyItem.itemType === "entry") {
                const effort = WeeklogUtils.getEntryEffort(historyItem, project.efforts);
                return (
                    <Link 
                        key={historyItem.id} 
                        to={`${process.env.PUBLIC_URL}/entries/${historyItem.slug}`} 
                        className={`entry-item${historyItem.versionSpan ? " version-span" : ""}`}
                        style={{
                            marginTop: entrySpaces[index][0],
                            backgroundColor: historyItem.bgColor || null
                        }}
                    >
                        {historyItem.versionSpan ? (
                            <div>
                                <div className="circle" style={{backgroundColor: WeeklogUtils.getStatusColor(effort ? effort.type : null)}}></div>
                                <div>
                                    <p className="summary">{isMobile ? historyItem.summary.substring(0,40) + (historyItem.summary.length > 30 ? "..." : "") : historyItem.summary}</p>
                                    {historyItem.versionSpan}
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="circle" style={{backgroundColor: WeeklogUtils.getStatusColor(effort ? effort.type : null)}}></div>
                                <p className="summary">{isMobile ? historyItem.summary.substring(0,40) + (historyItem.summary.length > 30 ? "..." : "") : historyItem.summary}</p>
                            </div>
                        )}
                        <div>
                            <p className="date">{moment(historyItem.timestamp, "x").format(!isMobile ? "Do MMMM YYYY" : "DD/MM/YYYY")}</p>
                        </div>
                        { projectHistory.length < 2 ? null : (
                            <div className="line" style={{
                                backgroundColor: WeeklogUtils.getStatusColor(effort ? effort.type : null),
                                height: entrySpaces[index][1],
                                top: entrySpaces[index][2]
                            }}></div>
                        )}
                    </Link>
                )
            } else if(historyItem.itemType === "effort-start") {
                return (
                    <div key={historyItem.id + "_s"} className="effort-item" style={{
                        borderColor: WeeklogUtils.getStatusColor(historyItem.type), 
                        color: WeeklogUtils.getStatusColor(historyItem.type),
                        backgroundColor: WeeklogUtils.getRgbString(WeeklogUtils.getStatusColor(historyItem.type), 0.05),
                        marginTop: entrySpaces[index][0]
                    }}>
                        <div>
                            <p>"{historyItem.summary}" Begins</p>
                            <p>Effort Type: {WeeklogUtils.capitalize(historyItem.type)}</p>
                        </div>
                        <p>{moment(historyItem.timestamp, "x").format(!isMobile ? "Do MMMM YYYY" : "DD/MM/YYYY")}</p>
                        <div className="line" style={{
                            backgroundColor: WeeklogUtils.getStatusColor(historyItem.type),
                            height: entrySpaces[index][1],
                            top: entrySpaces[index][2]
                        }}></div>
                    </div>
                )
            } else if(historyItem.itemType === "effort-end") {
                return (
                    <div key={historyItem.id + "_e"} className="effort-item" style={{
                        borderColor: WeeklogUtils.getStatusColor(historyItem.type), 
                        color: WeeklogUtils.getStatusColor(historyItem.type),
                        backgroundColor: WeeklogUtils.getRgbString(WeeklogUtils.getStatusColor(historyItem.type), 0.05),
                        marginTop: entrySpaces[index][0]
                    }}>
                        <div>
                            <p>"{historyItem.summary}" Ends</p>
                            <p>New Version: {historyItem.newVersionNumber}</p>
                        </div>
                        <p>{moment(historyItem.timestamp, "x").format(!isMobile ? "Do MMMM YYYY" : "DD/MM/YYYY")}</p>
                        <div className="line" style={{
                            backgroundColor: "#A3A3A3",
                            height: entrySpaces[index][1],
                            top: entrySpaces[index][2]
                        }}></div>
                    </div>
                )
            } else return null;
        }))

    }, [project, isMobile])

    return (
        <div className="project-detail-entries">
            <h2>Entries</h2>
            <div className="new-entry-container">
                {!entries || entries.length === 0 ? (
                    <p>No entries yet!</p>
                ) : entries}
            </div>
        </div>
    )
}

export default ProjectDetailEntries;