import React, {useState, useMemo, useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'

import moment from 'moment'
import { useTable, useSortBy } from 'react-table'

import WeeklogUtils from '../../shared/WeeklogUtils'

const DashboardProjectDownloads = ({project}) => {
    const [data, setData] = useState([]);
    const columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Date',
            accessor: 'timestamp',
            Cell: ({value}) => {
                return moment(value, "x").format(window.innerWidth > 640 ? "Do MMMM YYYY" : "DD/MM/YY")
            }
        },
        {
            Header: 'id',
            accessor: 'id'
        },
        {
            Header: 'slug',
            accessor: 'slug'
        }
    ], []);
    
    useEffect(() => {
        if(!project || !project.downloads || project.downloads.length === 0) {
            setData([]);
            return;
        }
        setData(project.downloads.map(download => {
            return {
                name: download.name,
                timestamp: download.timestamp,
                id: download.id,
                slug: download.slug
            }
        }))
    }, [project]);
    const history = useHistory();

    const navigateToDownload = slug => {
        history.push(`${process.env.PUBLIC_URL}/downloads/${slug}`);
    }

    return (
        <div>
            <div className="dashboard-buttons">
                <Link to={`${process.env.PUBLIC_URL}/downloads/add/${project.id}`} className="hover-tile">Create New Download</Link>
            </div>
            {!data || data.length === 0
                ? <p>No downloads found</p>
                : <Table columns={columns} data={data} onRowClick={navigateToDownload} />
            }
        </div>
    )
}

const Table = ({columns, data, onRowClick}) => {
    const tableInstance = useTable({
        columns, 
        data, 
        initialState: {
            hiddenColumns: ["id", "slug"],
            sortBy: [
                {
                    id: 'timestamp',
                    desc: true
                }
            ]
        }
    }, useSortBy);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    if(!data || data.length === 0) return null;

    return (
        <table {...getTableProps()} className="dashboard-project-table">
            <thead>
            {// Loop over the header rows
            headerGroups.map(headerGroup => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                headerGroup.headers.map(column => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        <span>
                            {column.isSorted 
                                ? column.isSortedDesc ? "▼" : "▲"
                                : "▶"
                            }
                        </span>
                    </th>
                ))}
                </tr>
            ))}
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
            {// Loop over the table rows
            rows.map(row => {
                // Prepare the row for display
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()} onClick={() => onRowClick(row.values.slug)} style={{
                        color: WeeklogUtils.getStatusColor(row.values.type)
                    }}>
                        {// Loop over the rows cells
                        row.cells.map(cell => {
                        // Apply the cell props
                        return (
                            <td {...cell.getCellProps()}>
                            {// Render the cell contents
                            cell.render('Cell')}
                            </td>
                        )
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

export default DashboardProjectDownloads;