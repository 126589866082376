import React, {useState, useContext, useEffect} from 'react'
import {Redirect, Prompt} from 'react-router-dom'

import {Formik, FieldArray} from 'formik';
import {Form, Autocomplete, Datepicker, SubmitBtn, Input, Textarea, Button, Radio} from 'react-formik-ui'
import moment from 'moment';
import * as yup from 'yup'

import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import ReactMarkdown from 'react-markdown'

import AddImageItem from '../common/AddImageItem'

import WeeklogUtils from '../../shared/WeeklogUtils'
import DataContext from '../../DataContext'
import UserContext from '../../UserContext'

import FirebaseConfig from'../../FirebaseConfig'

import '../../css/form.css'

const EditEntry = ({match}) => {

    const {user} = useContext(UserContext);
    const {data, forceUpdateData} = useContext(DataContext);

    const [initialValues, setInitialValues] = useState({
        summary: "",
        label: "",
        date: moment().startOf("day").add(12, "hour").toDate(), //to prevent dirty from constantly changing
        project: "",
        content: "",
        images: []
    });
    const [entry, setEntry] = useState(null);
    const [suggestions, setSuggestions] = useState({});
    const [editComplete, setEditComplete] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isUndeleting, setIsUndeleting] = useState(false);
    const [isShowingPreview, setIsShowingPreview] = useState(false);

    useEffect(() => {
        setSuggestions(data.projects.map(project => project.name));
    }, [data.projects]);

    useEffect(() => {
        const rawEntry = data.entries.find(e => e.id === match.params.eid);
        if(rawEntry === null) {
            setEntry(null);
        } else {
            setEntry({...rawEntry, project: data.projects[rawEntry.project.index]});
        }
    }, [data.entries, data.projects, match.params.eid]);

    useEffect(() => {
        if(entry) {
            setInitialValues({
                summary: entry.summary,
                label: entry.label ?? "note",
                date: moment(entry.timestamp, "x").toDate(),
                project: entry.project.name,
                content: entry.content,
                images: entry.images
            });
        }
    }, [entry])

    const handleSubmit = (values, {setSubmitting}) => {

        const doEditEntry = async(data, oldProjectId, newProjectId, callback) => {
            firebase.auth().currentUser.getIdToken(true).then(idToken => {
                const patchEntry = async () => {
                    await axios.patch(`${FirebaseConfig.databaseURL}/entries/${entry.id}.json?auth=${idToken}`, data);
                    if(oldProjectId !== newProjectId) {
                        await axios.patch(`${FirebaseConfig.databaseURL}/projects/${newProjectId}/entries.json?auth=${idToken}`, {[entry.id] : true});
                        await axios.delete(`${FirebaseConfig.databaseURL}/projects/${oldProjectId}/entries/${entry.id}.json?auth=${idToken}`);
                    }
                    setEditComplete(true);
                    forceUpdateData();
                }
                
                patchEntry();
            }).catch(err => {
                console.log("Error submitting", err);
                callback();
            })
        }

        setSubmitting(true);

        const originalTime = moment(entry.timestamp, "x");
        const finalTimestamp = moment(values.date).startOf("day")
            .add(originalTime.hour(), "hours")
            .add(originalTime.minute(), "minutes")
            .add(originalTime.second(), "seconds").valueOf();

        const projectIndex = data.projects.findIndex(project => project.name === values.project);
        if(projectIndex === -1) {
            console.log("Invalid project, somehow?");
            setSubmitting(false);
            return;
        }

        const newEntryData = {
            summary: values.summary,
            label: values.label,
            slug: WeeklogUtils.getUniqueSlug(data, values.summary, data.projects[projectIndex], entry.id),
            project: data.projects[projectIndex].id,
            timestamp: finalTimestamp,
            content: values.content,
            images: !values.images || values.images.length === 0 ? false : values.images.map(image => {
                if(image.caption === "") return {
                    ref: image.ref,
                    name: image.name,
                    size: image.size,
                    type: image.type,
                    url: image.url,
                    caption: ""
                };
                else return {
                    ref: image.ref,
                    name: image.name,
                    size: image.size,
                    type: image.type,
                    url: image.url,
                    caption: image.caption
                };
            })
        }

        doEditEntry(newEntryData, entry.project.id, data.projects[projectIndex].id, () => setSubmitting(false));
    }

    const handleDelete = () => {
        const doDeleteEntry = async(callback) => {
            firebase.auth().currentUser.getIdToken(true).then(idToken => {
                const deleteEntry = async () => {
                    await axios.patch(`${FirebaseConfig.databaseURL}/entries/${entry.id}.json?auth=${idToken}`, {isTrashed: true});
                    setEditComplete(true);
                    forceUpdateData();
                }
                
                deleteEntry();
            }).catch(err => {
                console.log("Error submitting", err);
                callback();
            })
        }

        setIsDeleting(true);
        doDeleteEntry(() => setIsDeleting(false));
    }

    const handleUndelete = () => {
        const doUndeleteEntry = async(callback) => {
            firebase.auth().currentUser.getIdToken(true).then(idToken => {
                const undeleteEntry = async () => {
                    await axios.patch(`${FirebaseConfig.databaseURL}/entries/${entry.id}.json?auth=${idToken}`, {isTrashed: false});
                    setEditComplete(true);
                    forceUpdateData();
                }
                
                undeleteEntry();
            }).catch(err => {
                console.log("Error submitting", err);
                callback();
            })
        }

        setIsUndeleting(true);
        doUndeleteEntry(() => setIsUndeleting(false));
    }

    const getSchema = () => {
        return yup.object().shape({
            summary: yup.string().required("A summary is required"),
            date: yup.date().when('project', (project, schema) => {
                project = data.projects.find(p => p.name === project);
                if(!project) return schema;
                return schema.test('date', "That project already has an entry for the selected day.", val => {
                    return !project.entries || project.entries.length === 0 || project.entries.reduce((acc, entry) => {
                        //note - we updated this to specifically ignore the current entry when comparing dates
                        return acc && (data.entries[entry.index].id === match.params.eid || moment(data.entries[entry.index].timestamp, "x").startOf("day").valueOf() !== moment(val).startOf("day").valueOf());
                    }, true)
                });
            }).required("A date is required"),
            project: yup.string().required("A project is required").oneOf(data.projects.map(project => project.name), "Invalid project"),
        });
    }

    if(!user) return <Redirect to={`${process.env.PUBLIC_URL}/`} />
    if(entry === null) return <div><h1>Entry not found!</h1></div> //TODO make a nice 404 page or something
    if(editComplete) return <Redirect to={`${process.env.PUBLIC_URL}/entries`} />

    if(isDeleting) {
        return (
            <div className="container">
                <h1>Edit Entry</h1>
                <p>Trashing Entry...</p>
            </div>
        )
    }

    if(isUndeleting) {
        return (
            <div className="container">
                <h1>Edit Entry</h1>
                <p>Removing Entry from Trash...</p>
            </div>
        )
    }

    return (
        <div className="container">
            <h1>Edit Entry</h1>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={getSchema}
                onSubmit={handleSubmit}
            >{({values, dirty, isSubmitting, setFieldValue}) => (
                <Form>
                    <Prompt when={dirty} message="You have unsaved changes. If you leave now, those changes will be lost." />
                    <Autocomplete name="project" label="Project" suggestions={suggestions} autoComplete="off" />
                    <Input name="summary" label="Summary" autoComplete="off" />
                    <div className={"option-" + values.label}>
                        <Radio inline name="label" label={`Label (${
                                values.label === "note" 
                                    ? "Note/Misc" : 
                                    values.label.substr(0, 1).toUpperCase() + values.label.substr(1)
                            })`} options={[
                                {value: "note", label: "📝"},
                                {value: "elaboration", label: "🎨"},
                                {value: "experimentation", label: "🧪"}
                            ]}/>
                    </div>
                    <Datepicker name="date" label="Date" />
                    <div className="content-previewable-wrapper">
                        <div className="content-previewable-label">
                            <span>Content</span>
                            <button type="button" onClick={() => setIsShowingPreview(!isShowingPreview)}>{isShowingPreview ? "Hide Preview" : "Show Preview"}</button>
                        </div>
                        {isShowingPreview ? <div className="content-preview">
                            <ReactMarkdown source={values.content} />
                            <div className="content-image-previews">
                                {values.images.map(image => {
                                    return image && image.url && image.url !== "" 
                                        ? <img key={image.ref} src={image.url} alt={image.name} /> 
                                        : null
                                })}
                            </div>
                        </div> : <Textarea name="content" label="" autoComplete="off" /> }
                    </div>
                    <FieldArray name="images" render={arrayHelpers => { return (
                        <div className="form-element images">
                            <div className="images-wrapper">
                                <span className="label">Images</span>
                                <div className="images-container">
                                {values.images && values.images.length > 0 ? (
                                    values.images.map((image, index) => { return (
                                        <AddImageItem key={"image-" + index} imageArrayName="images" index={index} arrayHelpers={arrayHelpers} values={values} setFieldValue={setFieldValue} />
                                    )})
                                ) : (
                                    null
                                )}
                                <div className="add-image-container">
                                    <button type="button" onClick={() => arrayHelpers.push({url: "", caption: ""})}>Add an Image</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    )}} />
                    <div className="form-element">
                        <div className="buttons-wrapper">   
                            <SubmitBtn disabled={isSubmitting || isDeleting} children={isSubmitting ? "Updating..." : "Update"} />
                            {entry.isTrashed 
                            ? <Button 
                                disabled={isSubmitting} 
                                className="button-danger" 
                                type="button" 
                                onClick={() => { if(window.confirm("Confirm remove entry from trash?")) handleUndelete(); }} 
                                children="Un-Trash Entry" />
                            : <Button 
                                disabled={isSubmitting} 
                                className="button-danger" 
                                type="button" 
                                onClick={() => { if(window.confirm("Confirm trash entry?")) handleDelete(); }} 
                                children="Trash Entry" />
                            }
                        </div>
                    </div>
                </Form>
            )}</Formik>
        </div>
    )
}

export default EditEntry;