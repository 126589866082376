import React, {useState, useContext, useEffect} from 'react'
import {Redirect, Prompt} from 'react-router-dom'

import {Formik, FieldArray} from 'formik';
import {Form, Autocomplete, Datepicker, SubmitBtn, Input, Textarea, Select, Button} from 'react-formik-ui'
import moment from 'moment';
import * as yup from 'yup'

import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import ReactMarkdown from 'react-markdown'

import AddTags from '../common/AddTags'
import AddImageItem from '../common/AddImageItem'
import AddHeroImage from '../common/AddHeroImage'

import WeeklogUtils from '../../shared/WeeklogUtils'
import DataContext from '../../DataContext'
import UserContext from '../../UserContext'

import FirebaseConfig from'../../FirebaseConfig'

import '../../css/form.css'

const statusOptions = [
    {label: "Concept", value: "concept"},
    {label: "Release", value: "release"},
    {label: "Experiment", value: "experiment"},
    {label: "Prerelease", value: "prerelease"},
    {label: "Prototype", value: "prototype"},
    {label: "Experimentation", value: "experimentation"},
    {label: "Stabilization", value: "stabilization"},
    {label: "Preparation", value: "preparation"},
    {label: "Elaboration", value: "elaboration"},
    {label: "Creation", value: "creation"},
];

const visibilityOptions = [
    {label: "Open", value: "open"},
    {label: "Secret", value: "secret"},
    {label: "Top-Secret", value: "top-secret"},
    {label: "Hidden", value: "hidden"},
];

const EditProject = ({match}) => {

    const {user} = useContext(UserContext);
    const {data, forceUpdateData} = useContext(DataContext);
    const [suggestions, setSuggestions] = useState({});
    const [advancedOpen, setAdvancedOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isUndeleting, setIsUndeleting] = useState(false);
    const [editComplete, setEditComplete] = useState(false);
    const [project, setProject] = useState(null);
    const [isShowingPreview, setIsShowingPreview] = useState(false);

    const [initialValues, setInitialValues] = useState({
        name: "",
        description: "",
        idea: "",
        status: "concept",
        version: "0.0.0",
        date: moment().startOf("day").add(12, "hour").toDate(), //to prevent dirty from constantly changing
        visibility: "open",
        content: "",
        featuredImages: false,
        heroImage: "",
        tags: "",
        tagEntry: ""
    });

    useEffect(() => {
        if(project) {
            setInitialValues({
                name: project.name,
                description: project.description,
                idea: project.idea.name,
                status: project.status,
                version: project.version,
                date: moment(project.timestamp, "x").toDate(),
                visibility: project.visibility,
                content: project.content,
                featuredImages: project.featuredImages ? project.featuredImages : [],
                heroImage: project.heroImage,
                tags: project.tags || "",
                tagEntry: ""
            });
        }
    }, [project])

    useEffect(() => {
        setSuggestions(data.ideas.map(idea => idea.name));
    }, [data.ideas]);

    useEffect(() => {
        const rawProject = data.projects.find(p => p.id === match.params.pid);
        if(rawProject === null) {
            setProject(null);
        } else {
            setProject({...rawProject, idea: data.ideas[rawProject.idea.index]});
        }
    }, [data.projects, data.ideas, match.params.pid]);

    const handleSubmit = (values, {setSubmitting}) => {

        const doEditProject = async(data, oldIdeaId, newIdeaId, callback) => {
            firebase.auth().currentUser.getIdToken(true).then(idToken => {
                const patchIdea = async () => {
                    await axios.patch(`${FirebaseConfig.databaseURL}/projects/${project.id}.json?auth=${idToken}`, data);
                    if(oldIdeaId !== newIdeaId) {
                        await axios.patch(`${FirebaseConfig.databaseURL}/ideas/${newIdeaId}/projects.json?auth=${idToken}`, {[project.id] : true});
                        await axios.delete(`${FirebaseConfig.databaseURL}/ideas/${oldIdeaId}/projects/${project.id}.json?auth=${idToken}`);
                    }
                    setEditComplete(true);
                    forceUpdateData();
                }
                
                patchIdea();
            }).catch(err => {
                console.log("Error submitting", err);
                callback();
            })
        }

        setSubmitting(true);

        const originalTime = moment(project.timestamp, "x");
        const finalTimestamp = moment(values.date).startOf("day")
            .add(originalTime.hour(), "hours")
            .add(originalTime.minute(), "minutes")
            .add(originalTime.second(), "seconds").valueOf();

        const ideaIndex = data.ideas.findIndex(idea => idea.name === values.idea);
        if(ideaIndex === -1) {
            console.log("Invalid idea, somehow?");
            setSubmitting(false);
            return;
        }

        const newProjectData = {
            name: values.name,
            slug: WeeklogUtils.getUniqueProjectSlug(data, values.name, project.id),
            description: values.description,
            idea: data.ideas[ideaIndex].id,
            status: values.status,
            version: values.version,
            timestamp: finalTimestamp,
            visibility: values.visibility,
            content: values.content,
            featuredImages: values.featuredImages.map(image => {
                if(image.caption === "") return {
                    ref: image.ref,
                    name: image.name,
                    size: image.size,
                    type: image.type,
                    url: image.url,
                    caption: ""
                };
                else return {
                    ref: image.ref,
                    name: image.name,
                    size: image.size,
                    type: image.type,
                    url: image.url,
                    caption: image.caption
                };
            }),
            heroImage: {
                ref: values.heroImage.ref,
                name: values.heroImage.name,
                size: values.heroImage.size,
                type: values.heroImage.type,
                url: values.heroImage.url
            },
            tags: values.tags.map(tag => tag.name)
        }

        doEditProject(newProjectData, project.idea.id, data.ideas[ideaIndex].id, () => setSubmitting(false));
    }

    const handleDelete = () => {
        const doDeleteProject = async(callback) => {
            firebase.auth().currentUser.getIdToken(true).then(idToken => {
                const deleteProject = async () => {
                    await axios.patch(`${FirebaseConfig.databaseURL}/projects/${project.id}.json?auth=${idToken}`, {isTrashed: true});
                    setEditComplete(true);
                    forceUpdateData();
                }
                
                deleteProject();
            }).catch(err => {
                console.log("Error submitting", err);
                callback();
            })
        }

        setIsDeleting(true);
        doDeleteProject(() => setIsDeleting(false));
    }

    const handleUndelete = () => {
        const doUndeleteProject = async(callback) => {
            firebase.auth().currentUser.getIdToken(true).then(idToken => {
                const undeleteProject = async () => {
                    await axios.patch(`${FirebaseConfig.databaseURL}/projects/${project.id}.json?auth=${idToken}`, {isTrashed: false});
                    setEditComplete(true);
                    forceUpdateData();
                }
                
                undeleteProject();
            }).catch(err => {
                console.log("Error submitting", err);
                callback();
            })
        }

        setIsUndeleting(true);
        doUndeleteProject(() => setIsUndeleting(false));
    }

    const getSchema = () => {
        return yup.object().shape({
            name: yup.string().required("A name is required"),
            description: yup.string().required("A description is required"),
            idea: yup.string().required("An idea is required").oneOf(data.ideas.map(idea => idea.name), "Invalid idea"),
            status: yup.string().required("A status is required").oneOf(statusOptions.map(option => option.value), "Invalid status"),
            version: yup.string().required("A version is required - set as 0.0.0 if you don't know what it should be"),
            date: yup.date().required("A date is required"),
            visibility: yup.string().required("A visibility is required").oneOf(visibilityOptions.map(option => option.value), "Invalid visibility"),
            content: yup.string().notRequired()
        });
    }

    if(!user) return <Redirect to={`${process.env.PUBLIC_URL}/`} />
    if(project === null) return <div><h1>Project not found!</h1></div> //TODO make a nice 404 page or something
    if(editComplete) return <Redirect to={`${process.env.PUBLIC_URL}/dashboard/project/${project.id}`} />

    if(isDeleting) {
        return (
            <div>
                <p>Trashing Project...</p>
            </div>
        )
    }

    if(isUndeleting) {
        return (
            <div>
                <p>Removing Project from Trash...</p>
            </div>
        )
    }

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={getSchema}
                onSubmit={handleSubmit}
            >{({values, dirty, isSubmitting, setFieldValue}) => (
                <Form>
                    <Prompt when={dirty} message="You have unsaved changes. If you leave now, those changes will be lost." />
                    <Input name="name" label="Name" autoComplete="off" />
                    <Input name="description" label="Description" autoComplete="off" />
                    <Autocomplete name="idea" label="Idea" suggestions={suggestions} autoComplete="off" />
                    <AddTags values={values} validTags={data.tags} />
                    <div className="content-previewable-wrapper">
                        <div className="content-previewable-label">
                            <span>Content</span>
                            <button type="button" onClick={() => setIsShowingPreview(!isShowingPreview)}>{isShowingPreview ? "Hide Preview" : "Show Preview"}</button>
                        </div>
                        {isShowingPreview ? <div className="content-preview">
                            <ReactMarkdown source={values.content} />
                            <div className="content-image-previews">
                                {values.featuredImages.map(image => {
                                    return image && image.url && image.url !== "" 
                                        ? <img key={image.ref} src={image.url} alt={image.name} /> 
                                        : null
                                })}
                            </div>
                        </div> : <Textarea name="content" label="" autoComplete="off" /> }
                    </div>
                    <AddHeroImage fieldName="heroImage" value={values.heroImage} setFieldValue={setFieldValue} />
                    <FieldArray name="featuredImages" render={arrayHelpers => { return (
                        <div className="form-element images">
                            <div className="images-wrapper">
                                <span className="label">Featured<br/>Images</span>
                                <div className="images-container">
                                {values.featuredImages && values.featuredImages.length > 0 ? (
                                    values.featuredImages.map((image, index) => { return (
                                        <AddImageItem key={"image-" + index} imageArrayName="featuredImages" index={index} arrayHelpers={arrayHelpers} values={values} setFieldValue={setFieldValue} />
                                    )})
                                ) : (
                                    null
                                )}
                                <div className="add-image-container">
                                    <button type="button" onClick={() => arrayHelpers.push({url: "", caption: ""})}>Add an Image</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    )}} />
                    <div className="form-element">
                        <div className="expand-wrapper">
                            <span>Advanced</span>
                            <span className="project-group-expander hover-tile" onClick={() => setAdvancedOpen(!advancedOpen)}>{advancedOpen ? "▼" : "▶"}</span>
                        </div>
                    </div>
                    <div className={advancedOpen ? null : "hidden"}>
                        <Select name="status" label="Status" options={statusOptions} />
                        <Input name="version" label="Version" autoComplete="off" />
                        <Datepicker name="date" label="Date" />
                        <Select name="visibility" label="Visibility" options={visibilityOptions} />
                    </div>
                    <div className="form-element">
                        <div className="buttons-wrapper">   
                            <SubmitBtn disabled={isSubmitting} children={isSubmitting ? "Submitting..." : "Submit"} />
                            {project.isTrashed 
                            ? <Button 
                                disabled={isSubmitting} 
                                className="button-danger" 
                                type="button" 
                                onClick={() => { if(window.confirm("Confirm remove project from trash?")) handleUndelete(); }} 
                                children="Un-Trash Project" />
                            : <Button 
                                disabled={isSubmitting} 
                                className="button-danger" 
                                type="button" 
                                onClick={() => { if(window.confirm("Confirm trash project?")) handleDelete(); }} 
                                children="Trash Project" />
                            }
                        </div>
                    </div>
                </Form>
            )}</Formik>
        </div>
    )
}

export default EditProject;