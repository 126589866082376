import React, {useState, useEffect} from 'react'

import WeeklogUtils from '../../shared/WeeklogUtils'

const rawFilters = [
    [
        "contract",
        "personal",
        "liminal",
    ],
    [
        "vr",
        "ar",
        "web",
        "electronics",
        "2d",
        "3d",
        "music",
        "miscellaneous",
    ],
    [
        "small",
        "medium",
        "large",
    ]
];

const ProjectFilters = ({activeFilters, numFilters, filterModes, toggleFilter, setMode}) => {

    const [filters, setFilters] = useState([[], [], []]);
    const [expanded, setExpanded] = useState(false);
    useEffect(() => {
        setFilters(rawFilters.map((group, index) => {
            return group.map(filterName => {
                return <li key={filterName}
                    className={activeFilters[index].findIndex(f => f === filterName) !== -1
                        ? "active-filter" : null
                    }
                    onClick={() => toggleFilter(filterName, index)}
                >
                    {filterName.length < 3 ? filterName.toUpperCase() : WeeklogUtils.capitalize(filterName)}
                </li>
            })
        }))
    }, [activeFilters, toggleFilter])

    return (
        <div className="filters">
            <h2 className="hover-tile" onClick={() => setExpanded(!expanded)}>Filters{numFilters > 0 ? ` (${numFilters} Active)` : null}</h2>
            {!expanded ? null : (
                <div>
                    <div>
                        <div className="one">
                            <h3>Target</h3>
                            <ul>
                                {filters[0]}
                            </ul>
                            <div className="match-button-container">
                                <button 
                                    onClick={() => setMode(0)}
                                    className="hover-tile match-button">{filterModes[0] === "OR" ? "Can Match Any" : "Must Match All"}</button>
                            </div>
                        </div>
                        <div className="three">
                            <h3>Discipline</h3>
                            <ul>
                                {filters[1]}
                            </ul>
                            <div className="match-button-container">
                                <button 
                                    onClick={() => setMode(1)}
                                    className="hover-tile match-button">{filterModes[1] === "OR" ? "Can Match Any" : "Must Match All"}</button>
                            </div>
                        </div>
                        <div className="one">
                            <h3>Size</h3>
                            <ul>
                                {filters[2]}
                            </ul>
                            <div className="match-button-container">
                                <button 
                                    onClick={() => setMode(2)}
                                    className="hover-tile match-button">{filterModes[2] === "OR" ? "Can Match Any" : "Must Match All"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ProjectFilters;