import React, {useEffect, useState} from 'react'

import moment from 'moment'

import EntryDay from './EntryDay'

import WeeklogUtils from '../../shared/WeeklogUtils'

import './css/entries.css'

const getWeekName = (date) => {
    let womDate = moment(date);
    return WeeklogUtils.getWeeklogWeek(womDate).prettyString;
}

const EntryWeek = ({entries}) => {

    const [dayEntries, setDayEntries] = useState([]);

    useEffect(() => {
        setDayEntries(() => {
            let dayList = {};
            entries.forEach(entry => {
                const day = moment(entry.timestamp, "x").dayOfYear() + (365 * moment(entry.timestamp, "x").year());
                if(dayList[day] === undefined) dayList[day] = [entry];
                else dayList[day] = [...dayList[day], entry];
            })
            dayList = Object.keys(dayList).sort((a, b) => { return b - a }).map(key => {
                return <EntryDay key={key} entries={dayList[key]} />
            });
            return dayList;
        })
    }, [entries])

    return (
        <div>
            <div className="entry-week-heading">
                <h2>Entries for {getWeekName(moment(entries[0].timestamp, "x"))}</h2>
                <h4>{moment(entries[0].timestamp, "x").startOf("isoWeek").format("Do MMMM")} to {moment(entries[0].timestamp, "x").endOf("isoWeek").format("Do MMMM")}</h4>
            </div>
            { dayEntries }
        </div>
    )
}

export default EntryWeek;