import React, {useState, useMemo, useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'

import moment from 'moment'
import { useTable, useSortBy } from 'react-table'

import WeeklogUtils from '../../shared/WeeklogUtils'

const DashboardProjectEfforts = ({project}) => {
    const [data, setData] = useState([]);
    const columns = useMemo(() => [
        {
            Header: 'Summary',
            accessor: 'summary',
        },
        {
            Header: 'Type',
            accessor: 'type',
        },
        {
            Header: 'StartDate',
            accessor: 'startDate',
            Cell: ({value}) => {
                return moment(value, "x").format(window.innerWidth > 640 ? "Do MMMM YYYY" : "DD/MM/YY")
            }
        },
        {
            Header: 'EndDate',
            accessor: 'endDate',
            Cell: ({value}) => {
                return !value ? "Ongoing" : moment(value, "x").format(window.innerWidth > 640 ? "Do MMMM YYYY" : "DD/MM/YY")
            }
        },
        {
            Header: 'id',
            accessor: 'id'
        }
    ], []);
    
    useEffect(() => {
        if(!project || !project.efforts || project.efforts.length === 0) {
            setData([]);
            return;
        }
        setData(project.efforts.map(effort => {
            return {
                summary: effort.summary,
                type: effort.type,
                startDate: effort.startDate,
                endDate: effort.endDate,
                id: effort.id,
            }
        }))
    }, [project]);
    const history = useHistory();

    const navigateToEffort = id => {
        history.push(`${process.env.PUBLIC_URL}/efforts/edit/${id}`);
    }

    
    

    return (
        <div>
            {WeeklogUtils.isStable(project.status) || (!project.efforts || project.efforts.length === 0)
            ? (
                <div className="dashboard-buttons">
                    <Link to={`${process.env.PUBLIC_URL}/efforts/add/${project.id}`} className="hover-tile">Start New Effort</Link>
                </div>
            )
            : (
                <div className="dashboard-buttons">
                    <Link to={`${process.env.PUBLIC_URL}/efforts/add/${project.id}`} className="hover-tile">Start New Effort</Link>
                    <Link to={`${process.env.PUBLIC_URL}/efforts/complete/${project.efforts[0].id}`} className="hover-tile">Complete Active Effort</Link>
                    <Link to={`${process.env.PUBLIC_URL}/efforts/edit/${project.efforts[0].id}`} className="hover-tile">Edit Active Effort</Link>
                </div>
            )}
            {!data || data.length === 0 
                ? <p>No efforts found</p>
                : <Table columns={columns} data={data} onRowClick={navigateToEffort} />
            }
        </div>
    )
}

const Table = ({columns, data, onRowClick}) => {
    const tableInstance = useTable({
        columns, 
        data, 
        initialState: {
            hiddenColumns: window.innerWidth > 640 ? ["id"] : ["id", "endDate"],
            sortBy: [
                {
                    id: 'startDate',
                    desc: true
                }
            ]
        }
    }, useSortBy);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    if(!data || data.length === 0) return null;

    return (
        <table {...getTableProps()} className="dashboard-project-table">
            <thead>
            {// Loop over the header rows
            headerGroups.map(headerGroup => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                headerGroup.headers.map(column => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        <span>
                            {column.isSorted 
                                ? column.isSortedDesc ? "▼" : "▲"
                                : "▶"
                            }
                        </span>
                    </th>
                ))}
                </tr>
            ))}
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
            {// Loop over the table rows
            rows.map(row => {
                // Prepare the row for display
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()} onClick={() => onRowClick(row.values.id)} style={{
                        color: WeeklogUtils.getStatusColor(row.values.type)
                    }}>
                        {// Loop over the rows cells
                        row.cells.map(cell => {
                        // Apply the cell props
                        return (
                            <td {...cell.getCellProps()}>
                            {// Render the cell contents
                            cell.render('Cell')}
                            </td>
                        )
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

export default DashboardProjectEfforts;