import React from 'react'

import TimelineCalcs from './TimelineCalcs'

const TimelineMonthMarker = ({month, globalTimeBounds}) => {
    const top = TimelineCalcs.monthMarkerTop(month, globalTimeBounds);
    // Our label that is positioned at the start of (eg) December is intended
    // for the month below it, and so we subtract one month to display "November"
    const label = month.subtract(1, "months").format("MMMM");

    return (
        <div className="timeline-month-marker" style={{
            top: `${top}px`,
            left: "0px",
        }}>
            <p>{label}</p>
        </div>
    )
}

export default TimelineMonthMarker
